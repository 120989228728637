
// import React, { FC, useContext, useRef, useEffect, useState } from "react";
// import LangState, { LangContext } from "../../context/lang";
// import Slider from "react-slick";
// import { NavLink } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

// import "../../scss/_homeServices.scss";

// import btn from "../../img/chevron-right.png";

// import Img1en from "../../img/en/home/services/1.Car insurance.png";
// import Img2en from "../../img/en/home/services/2.Car services.png";
// import Img3en from "../../img/en/home/services/3.Car value.png";
// import Img4en from "../../img/en/home/services/4.Auction.png";
// import Img5en from "../../img/en/home/services/5.Roadside assistance.png";
// import Img6en from "../../img/en/home/services/6.Car parking.png";
// import Img7en from "../../img/en/home/services/7.Fuel services.png";
// import Img8en from "../../img/en/home/services/8.Accident reporting.png";
// import Img9en from "../../img/en/home/services/9.Traffic fines.png";
// import Img1uk from "../../img/uk/home/services/1.png";
// import Img2uk from "../../img/uk/home/services/2.png";
// import Img3uk from "../../img/uk/home/services/3.png";
// import Img4uk from "../../img/uk/home/services/4.png";
// import Img5uk from "../../img/uk/home/services/5.png";
// import Img6uk from "../../img/uk/home/services/6.png";
// import Img7uk from "../../img/uk/home/services/7.png";
// import Img8uk from "../../img/uk/home/services/8.png";
// import Img9uk from "../../img/uk/home/services/9.png";
// import Img1tr from "../../img/tr/home/services/1.Sigorta.png";
// import Img2tr from "../../img/tr/home/services/2.Oto servisleri.png";
// import Img3tr from "../../img/tr/home/services/3.Arac.png";
// import Img4tr from "../../img/tr/home/services/4.Ac.png";
// import Img5tr from "../../img/tr/home/services/5.Yol yard--m.png";
// import Img6tr from "../../img/tr/home/services/6.Otopark.png";
// import Img7tr from "../../img/tr/home/services/7.Yak-t.png";
// import Img8tr from "../../img/tr/home/services/8.Kaza tespit tutanag.png";
// import Img9tr from "../../img/tr/home/services/9.Trafik cezalar.png";

// import Img1 from "../../img/home/services/1.insurance.png";
// import Img2 from "../../img/home/services/2.services.png";
// import Img3 from "../../img/home/services/3.value.png";
// import Img4 from "../../img/home/services/4.auction.png";
// import Img5 from "../../img/home/services/5.assistance.png";
// import Img6 from "../../img/home/services/6.parking.png";
// import Img7 from "../../img/home/services/7.fuel.png";
// import Img8 from "../../img/home/services/8.accident.png";
// import Img9 from "../../img/home/services/9.fine.png";

// // Images for Grid. src
// const images = {
//   EN: {
//     img1: Img1en,
//     img2: Img2en,
//     img3: Img3en,
//     img4: Img4en,
//     img5: Img5en,
//     img6: Img6en,
//     img7: Img7en,
//     img8: Img8en,
//     img9: Img9en,
//   },
//   UK: {
//     img1: Img1uk,
//     img2: Img2uk,
//     img3: Img3uk,
//     img4: Img4uk,
//     img5: Img5uk,
//     img6: Img6uk,
//     img7: Img7uk,
//     img8: Img8uk,
//     img9: Img9uk,
//   },
//   TR: {
//     img1: Img1tr,
//     img2: Img2tr,
//     img3: Img3tr,
//     img4: Img4tr,
//     img5: Img5tr,
//     img6: Img6tr,
//     img7: Img7tr,
//     img8: Img8tr,
//     img9: Img9tr,
//   },
// };

// type Lang = "EN" | "UK" | "TR";
// const HomeServices: FC = () => {
//   const {
//     state: { language },
//     dispatch: { translate },
//   } = useContext(LangContext);

//   // Images for Dots
//   const arr = [
//     {
//       img: {
//         src: Img1,
//         alt: "home_slider_header_insurance",
//       },
//     },
//     {
//       img: {
//         src: Img2,
//         alt: "home_slider_header_services",
//       },
//     },
//     {
//       img: {
//         src: Img3,
//         alt: "home_slider_header_value",
//       },
//     },
//     {
//       img: {
//         src: Img4,
//         alt: "home_slider_header_auction",
//       },
//     },
//     {
//       img: {
//         src: Img5,
//         alt: "home_slider_header_assistance",
//       },
//     },
//     {
//       img: {
//         src: Img6,
//         alt: "home_slider_header_parking",
//       },
//     },
//     {
//       img: {
//         src: Img7,
//         alt: "home_slider_header_fuel",
//       },
//     },
//     {
//       img: {
//         src: Img8,
//         alt: "home_slider_header_accident",
//       },
//     },
//     {
//       img: {
//         src: Img9,
//         alt: "home_slider_header_fines",
//       },
//     },
//   ];

//   let settings = {
//     dots: true,
//     arrows: true,
//     // infinite: false,
//     speed: 800,
//     autoplay: true,
//     pauseOnFocus: true,
//     pauseOnHover: true,
//     draggable: true,
//     lazyload: true,
//     autoplaySpeed: 5000,
//     swipeToSlide: true,
//     touchMove: true,
//     slidesToShow: 1,
//     // dotsToShow: 5,
//     slidesToScroll: 1,
//     cssEase: 'linear',
//     waitForAnimate: false,
//     adaptiveHeight: true,
//     customPaging: function (i: any) {
//       console.log('🔥🚀 => settings.i:', i);
//       return (
//         <div className="custom_dots" >
//           <div className="home_slider_li-img">
//             <img src={arr[i].img.src} alt={arr[i].img.alt} draggable="false" />
//           </div>
//           <p className="dots_text">{translate(arr[i].img.alt)}</p>
//         </div>
//       );
//     },
//   };

//   return (
//     <section className="home_services">
//       <div className="cont cont_sm">
//         <h6>{translate("home_services_name")}</h6>
//         <h2 id="feature">{translate("home_services_bigtitle")}</h2>
//       </div>
//       <div className="home_slider-styles">
//         <Slider {...settings}>
//           <div className="grid insurance">
//             <div className="item">
//               <div className="img-wrap">
//                 <img
//                   src={images[language as Lang].img1}
//                   alt="insurance"
//                   draggable="false"
//                 />
//               </div>
//             </div>
//             <div className="item">
//               <div className="item-content">
//                 <h3>{translate("home_slider_title_insurance")}</h3>
//                 <p id="item-hometext">
//                   {translate("home_slider_text_insurance")}
//                 </p>
//               </div>
//               <div className="item-btn">
//                 <p>Read more</p>
//                 <NavLink to={`/${language.toLowerCase()}/insurance`}>
//                   <button className="home_slider-btn">
//                     <img src={btn} alt="More" draggable="false" />
//                   </button>
//                 </NavLink>
//               </div>
//             </div>
//           </div>
//           <div className="grid services">
//             <div className="item">
//               <div className="img-wrap">
//                 <img
//                   src={images[language as Lang].img2}
//                   alt="services"
//                   draggable="false"
//                 />
//               </div>
//             </div>
//             <div className="item">
//               <div className="item-content">
//                 <h3>{translate("home_slider_title_services")}</h3>
//                 <p id="item-hometext">
//                   {translate("home_slider_text_services")}
//                 </p>
//               </div>
//               <div className="item-btn">
//                 <p>Read more</p>
//                 <NavLink to={`/${language.toLowerCase()}/bookpay`}>
//                   <button className="home_slider-btn">
//                     <img src={btn} alt="More" draggable="false" />
//                   </button>
//                 </NavLink>
//               </div>
//             </div>
//           </div>
//           <div className="grid value">
//             <div className="item">
//               <div className="img-wrap">
//                 <img
//                   src={images[language as Lang].img3}
//                   alt="value"
//                   draggable="false"
//                 />
//               </div>
//             </div>
//             <div className="item">
//               <div className="item-content">
//                 <h3>{translate("home_slider_title_value")}</h3>
//                 <p id="item-hometext">{translate("home_slider_text_value")}</p>
//               </div>
//             </div>
//           </div>
// <div className="grid auction">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img4}
//         alt="auction"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_auction")}</h3>
//       <p id="item-hometext">
//         {translate("home_slider_text_auction")}
//       </p>
//     </div>
//   </div>
// </div>
// <div className="grid assistance">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img5}
//         alt="assistance"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_assistance")}</h3>
//       <p id="item-hometext">
//         {translate("home_slider_text_assistance")}
//       </p>
//     </div>
//   </div>
// </div>
// <div className="grid parking">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img6}
//         alt="parking"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_parking")}</h3>
//       <p id="item-hometext">
//         {translate("home_slider_text_parking")}
//       </p>
//     </div>
//   </div>
// </div>
// <div className="grid fuel">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img7}
//         alt="fuel"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_fuel")}</h3>
//       <p id="item-hometext">{translate("home_slider_text_fuel")}</p>
//     </div>
//   </div>
// </div>
// <div className="grid accident">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img8}
//         alt="accident"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_accident")}</h3>
//       <p id="item-hometext">
//         {translate("home_slider_text_accident")}
//       </p>
//     </div>
//   </div>
// </div>
// <div className="grid fines">
//   <div className="item">
//     <div className="img-wrap">
//       <img
//         src={images[language as Lang].img9}
//         alt="fines"
//         draggable="false"
//       />
//     </div>
//   </div>
//   <div className="item">
//     <div className="item-content">
//       <h3>{translate("home_slider_title_fines")}</h3>
//       <p id="item-hometext">{translate("home_slider_text_fines")}</p>
//     </div>
//   </div>
// </div>
//         </Slider>
//       </div>
//     </section>
//   );
// };

// export default HomeServices;

import React, { FC, useContext, useRef, useEffect, useState } from "react";
import { LangContext } from "../../context/lang";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "../../scss/_homeServices.scss";

import btn from "../../img/chevron-right.png";

import Img1en from "../../img/en/home/services/1.Car insurance.png";
import Img2en from "../../img/en/home/services/2.Car services.png";
import Img3en from "../../img/en/home/services/3.Car value.png";
import Img4en from "../../img/en/home/services/4.Auction.png";
import Img5en from "../../img/en/home/services/5.Roadside assistance.png";
import Img6en from "../../img/en/home/services/6.Car parking.png";
import Img7en from "../../img/en/home/services/7.Fuel services.png";
import Img8en from "../../img/en/home/services/8.Accident reporting.png";
import Img9en from "../../img/en/home/services/9.Traffic fines.png";
import Img1uk from "../../img/uk/home/services/1.png";
import Img2uk from "../../img/uk/home/services/2.png";
import Img3uk from "../../img/uk/home/services/3.png";
import Img4uk from "../../img/uk/home/services/4.png";
import Img5uk from "../../img/uk/home/services/5.png";
import Img6uk from "../../img/uk/home/services/6.png";
import Img7uk from "../../img/uk/home/services/7.png";
import Img8uk from "../../img/uk/home/services/8.png";
import Img9uk from "../../img/uk/home/services/9.png";
import Img1tr from "../../img/tr/home/services/1.Sigorta.png";
import Img2tr from "../../img/tr/home/services/2.Oto servisleri.png";
import Img3tr from "../../img/tr/home/services/3.Arac.png";
import Img4tr from "../../img/tr/home/services/4.Ac.png";
import Img5tr from "../../img/tr/home/services/5.Yol yard--m.png";
import Img6tr from "../../img/tr/home/services/6.Otopark.png";
import Img7tr from "../../img/tr/home/services/7.Yak-t.png";
import Img8tr from "../../img/tr/home/services/8.Kaza tespit tutanag.png";
import Img9tr from "../../img/tr/home/services/9.Trafik cezalar.png";

import Img1 from "../../img/home/services/1.insurance.png";
import Img2 from "../../img/home/services/2.services.png";
import Img3 from "../../img/home/services/3.value.png";
import Img4 from "../../img/home/services/4.auction.png";
import Img5 from "../../img/home/services/5.assistance.png";
import Img6 from "../../img/home/services/6.parking.png";
import Img7 from "../../img/home/services/7.fuel.png";
import Img8 from "../../img/home/services/8.accident.png";
import Img9 from "../../img/home/services/9.fine.png";

// Images for dots
const arr = [
  {
    img: {
      src: Img1,
      alt: "home_slider_header_insurance",
    },
  },
  {
    img: {
      src: Img2,
      alt: "home_slider_header_services",
    },
  },
  {
    img: {
      src: Img3,
      alt: "home_slider_header_value",
    },
  },
  {
    img: {
      src: Img4,
      alt: "home_slider_header_auction",
    },
  },
  {
    img: {
      src: Img5,
      alt: "home_slider_header_assistance",
    },
  },
  {
    img: {
      src: Img6,
      alt: "home_slider_header_parking",
    },
  },
  {
    img: {
      src: Img7,
      alt: "home_slider_header_fuel",
    },
  },
  {
    img: {
      src: Img8,
      alt: "home_slider_header_accident",
    },
  },
  {
    img: {
      src: Img9,
      alt: "home_slider_header_fines",
    },
  },
];

// Images for Grid. src
const images = {
  EN: {
    img1: Img1en,
    img2: Img2en,
    img3: Img3en,
    img4: Img4en,
    img5: Img5en,
    img6: Img6en,
    img7: Img7en,
    img8: Img8en,
    img9: Img9en,
  },
  UK: {
    img1: Img1uk,
    img2: Img2uk,
    img3: Img3uk,
    img4: Img4uk,
    img5: Img5uk,
    img6: Img6uk,
    img7: Img7uk,
    img8: Img8uk,
    img9: Img9uk,
  },
  TR: {
    img1: Img1tr,
    img2: Img2tr,
    img3: Img3tr,
    img4: Img4tr,
    img5: Img5tr,
    img6: Img6tr,
    img7: Img7tr,
    img8: Img8tr,
    img9: Img9tr,
  },
};

type Lang = "EN" | "UK" | "TR";

const HomeServices: FC = () => {
  const { state: { language }, dispatch: { translate } } = useContext(LangContext);
  const [viewedSlides, setViewedSlides] = useState<number[]>([0]);

  const handleSlideChange = (index: number) => {
    if (viewedSlides.includes(index)) {
      if (index === 0) {
        setViewedSlides([0]);
      }

      const lastViewedSlide = viewedSlides.length > 0 ? viewedSlides[viewedSlides.length - 1] : -1;

      if (index < lastViewedSlide) {
        setViewedSlides(prev => {
          const newList = [...prev];
          newList.pop();
          return newList;
        });
      }
    } else {
      const lastViewedSlide = viewedSlides.length > 0 ? viewedSlides[viewedSlides.length - 1] : -1;

      if (index - lastViewedSlide > 1) {
        const missedSlides: number[] = [];
        for (let i = lastViewedSlide + 1; i < index; i++) {
          missedSlides.push(i);
        }

        setViewedSlides(prev => [...prev, ...missedSlides, index]);
      } else {
        setViewedSlides(prev => [...prev, index]);
      }
    }
  };

  const renderIndicator = (
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
    isSelected: boolean,
    index: number,
    label: string
  ): React.ReactNode => {
    if (viewedSlides.includes(index) && !isSelected) {
      return null;
    }

    // if (isSelected && !viewedSlides.includes(index)) {
    //   setViewedSlides(prev => [...prev, index]);

    //   if (viewedSlides.length === arr.length - 1) {
    //     setViewedSlides([]);
    //   }
    // }

    return (
      <li className="custom_dots" onClick={onClickHandler} title={label}
        style={
          isSelected
            ? { backgroundColor: '#191d22' }
            : {}}
      >
        <div className="home_slider_li-img">
          <img src={arr[index].img.src} alt={arr[index].img.alt} draggable="false"
            style={
              isSelected
                ? {}
                : {
                  filter: "grayscale(100%)",
                  background: '#191d22'
                }
            }
          />
        </div>
        <p className="dots_text">{translate(arr[index].img.alt)}</p>
      </li>
    );
  };

  return (
    <section className="home_services">
      <div className="cont cont_sm">
        <h6>{translate("home_services_name")}</h6>
        <h2 id="feature">{translate("home_services_bigtitle")}</h2>
      </div>
      <div className="home_slider_styles">
        <Carousel
          className='home_services_slider'
          showThumbs={false}
          showArrows={true}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          transitionTime={800}
          stopOnHover={true}
          swipeable={true}
          // emulateTouch={true}
          dynamicHeight={true}
          onChange={handleSlideChange}
          renderIndicator={renderIndicator}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev &&
            <button onClick={onClickHandler} title={label} className="slick-arrow slick-prev">
              -
            </button>
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext &&
            <button onClick={onClickHandler} title={label} className="slick-arrow slick-next">
              +
            </button>
          }
        >
          <div className="grid insurance">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img1}
                  alt="insurance"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_insurance")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_insurance")}
                </p>
              </div>
              <div className="item-btn">
                <p>Read more</p>
                <NavLink to={`/${language.toLowerCase()}/insurance`}>
                  <button className="home_slider-btn">
                    <img src={btn} alt="More" draggable="false" />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="grid services">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img2}
                  alt="services"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_services")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_services")}
                </p>
              </div>
              <div className="item-btn">
                <p>Read more</p>
                <NavLink to={`/${language.toLowerCase()}/bookpay`}>
                  <button className="home_slider-btn">
                    <img src={btn} alt="More" draggable="false" />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="grid value">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img3}
                  alt="value"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_value")}</h3>
                <p id="item-hometext">{translate("home_slider_text_value")}</p>
              </div>
            </div>
          </div>
          <div className="grid auction">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img4}
                  alt="auction"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_auction")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_auction")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid assistance">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img5}
                  alt="assistance"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_assistance")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_assistance")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid parking">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img6}
                  alt="parking"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_parking")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_parking")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid fuel">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img7}
                  alt="fuel"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_fuel")}</h3>
                <p id="item-hometext">{translate("home_slider_text_fuel")}</p>
              </div>
            </div>
          </div>
          <div className="grid accident">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img8}
                  alt="accident"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_accident")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_accident")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid fines">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img9}
                  alt="fines"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_fines")}</h3>
                <p id="item-hometext">{translate("home_slider_text_fines")}</p>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default HomeServices;
