import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";
import ListItem from "../ListItem";
import Img1En from "../../img/en/insurance/protection/enInsProt1.png";
import Img2En from "../../img/en/insurance/protection/enInsProt2.png";
import Img3En from "../../img/en/insurance/protection/enInsProt3.png";
import Img1Uk from "../../img/uk/insurance/protection/ukInsProt1.png";
import Img2Uk from "../../img/uk/insurance/protection/ukInsProt2.png";
import Img3Uk from "../../img/uk/insurance/protection/ukInsProt3.png";
import Img1Tr from "../../img/tr/insurance/protection/trInsProt1.png";
import Img2Tr from "../../img/tr/insurance/protection/trInsProt2.png";
import Img3Tr from "../../img/tr/insurance/protection/trInsProt3.png";

import "../../scss/_insuranceProtection.scss";

// Images for Grid. src
const images = {
  EN: {
    img1: Img1En,
    img2: Img2En,
    img3: Img3En,
  },
  UK: {
    img1: Img1Uk,
    img2: Img2Uk,
    img3: Img3Uk,
  },
  TR: {
    img1: Img1Tr,
    img2: Img2Tr,
    img3: Img3Tr,
  },
};



type Lang = "EN" | "UK" | "TR";

const Protection: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <section className="insurance_protection ">
      <div className="cont cont_md">
        <h6>{translate("protection")}</h6>
        <h2 id="feature">{translate("protection_subtitle")}</h2>

        <div className="grid">
          <div className="item">
            <div>
              <div className="img-wrap">
                <img src={images[language as Lang].img1} alt="insurance_protection1" draggable="false"/>
              </div>
              <ListItem
                header={translate("Prot_title1")}
                text={translate("Prot_text1")}
              />
            </div>
          </div>
          <div className="item">
            <div>
              <div className="img-wrap">
                <img src={images[language as Lang].img2} alt="insurance_protection2" draggable="false"/>
              </div>
              <ListItem
                header={translate("Prot_title2")}
                text={translate("Prot_text2")}
              />
            </div>
          </div>
          <div className="item">
            <div>
              <div className="img-wrap">
                <img src={images[language as Lang].img3} alt="insurance_protection3" draggable="false"/>
              </div>
              <ListItem
                header={translate("Prot_title3")}
                text={translate("Prot_text3")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Protection;
