import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../context/lang";

import Ellipse from "../img/insurance/benefits/Ellipse.png";

// Preparation for content
interface IBenefitsProps {
  header1?: string;
  header2?: string;
  content1: string;
  content2: string;
  content3: string;
  content4: string;
  content5: string;
  content6: string;
}

const Benefits: FC<IBenefitsProps> = ({
  header1,
  header2,
  content1,
  content2,
  content3,
  content4,
  content5,
  content6,
}) => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <div className="benefits">
      <div className="cont cont_md">
        <div className="">
          <h6>{header1}</h6>
          <h2>{header2}</h2>
        </div>
        <div className="grid">
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content1}</p>
            </div>
          </div>
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content2}</p>
            </div>
          </div>
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content3}</p>
            </div>
          </div>
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content4}</p>
            </div>
          </div>
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content5}</p>
            </div>
          </div>
          <div className="item">
            <div className="unodered-list">
              <div className="round">
                <img className="ellipse" src={Ellipse} alt="ellipse" draggable="false"/>
              </div>
              <p id="item-text">{content6}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
