import { ILangs, IMediaFile } from '../../../store/interfaces';
import { Timestamp } from 'firebase/firestore'

// enum ServicePointProviderType {
//     'ACTIVE' = 'ACTIVE',
//     'DRAFT' = 'DRAFT',
//     'ARCHIVE' = 'ARCHIVE',
// }
export enum ServiceCategory {
    REPAIR = 'REPAIR',
    TYRE = 'TYRE',
    WASH = 'WASH',
    BODY_REPAIR = 'BODY_REPAIR',
    INSPECTION = 'INSPECTION',
}
export enum Applications {
    AutoKing = 'AutoKing',
    Beep = 'Beep',
    ALL = 'ALL'
}
export type WorkingHours = {
    from: string | null;
    to: string | null;
};

export interface IAddress {
    placeId: string;
    name: ILangs
}

interface IServicePoint {
    id?: string;
    _app?: Applications;
    _dateCreate?: Timestamp | Date;
    userId?: string;
    status?: ServiceCategory;

    name?: string;
    address?: IAddress;
    phone?: string;
    code?: string;
    type?: string[];
    vehicleType?: { id: string; value: string }[];
    vehicleBrand?: { id: string; value: string }[];
    description?: string;
    additionalServices?: string[];
    rating?: number;
    reviews?: number;
    workingTime?: {
        days: string[];
        time: WorkingHours;
    };
    additionalWorkingTime?: {
        days: string[];
        time: WorkingHours;
    } | null;
    photos?: IMediaFile[];
    logo?: IMediaFile | null;
    origin?: 'WEB' | 'APP'
}

export default IServicePoint;