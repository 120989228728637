import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";

import "../../scss/_bookpayBenefits.scss";
import Benefits from "../Benefits";

const BookpayBenefits: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // content for the component Benefits
  return (
    <section className="bookpay_benefits">
      <svg
        width="1920"
        height="764"
        viewBox="0 0 1920 764"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-d"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-114.03 316.269C-59.0758 265.701 -5.26271 221.355 45.426 185.11C349.28 -32.163 694.994 -61.1907 1017.89 122.623C1340.79 306.436 1245.2 724.826 1573.84 761.174C1763.73 782.176 1856.02 602.692 2024.66 576.877"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="1023"
        height="703"
        viewBox="0 0 1023 703"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-t"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-359 127.027C-313.308 100.407 -269.35 77.9168 -228.785 60.5542C14.384 -43.5261 257.89 -11.0544 453.267 165.15C648.644 341.354 518.94 616.181 740.697 691.27C868.833 734.658 959.945 624.556 1300 632.335"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="767"
        height="703"
        viewBox="0 0 767 703"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-m"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-646 127.027C-600.308 100.408 -556.35 77.9173 -515.785 60.5547C-272.616 -43.5256 -29.1099 -11.0539 166.267 165.15C361.644 341.354 231.94 616.181 453.697 691.271C581.833 734.659 672.945 624.557 793.5 632.336"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <Benefits
        header1={translate("bookpay_benefits_subtitle")}
        header2={translate("bookpay_benefits_title")}
        content1={translate("bookpay_benefits_item1")}
        content2={translate("bookpay_benefits_item2")}
        content3={translate("bookpay_benefits_item3")}
        content4={translate("bookpay_benefits_item4")}
        content5={translate("bookpay_benefits_item5")}
        content6={translate("bookpay_benefits_item6")}
      />
    </section>
  );
};

export default BookpayBenefits;
