import React, { useReducer, FC, ReactNode, createContext } from "react";

import en from '../i18n/en.json'
import tr from '../i18n/tr.json'
import uk from '../i18n/uk.json'
import Langs from '../components/interfaces/enums/Langs'

enum LangActionType {
    SET_LANGUAGE = 'SET_LANGUAGE'
}

interface ILangState {
    language: string
}

interface LangStateProps {
    children: ReactNode
}

interface SetLanguageAction {
    type: typeof LangActionType.SET_LANGUAGE
    payload: string
}

interface ContextProps {
    state: ILangState
    dispatch: {
        setLanguage: (lang: string) => void
        translate: (key: string) => string
    }
}

const langReducer = (state: ILangState, action: SetLanguageAction): ILangState => {
    switch (action.type) {
        case LangActionType.SET_LANGUAGE:
            return {
                language: action.payload
            }
        default:
            return state
    }
}

export const LangContext = createContext({} as ContextProps)

const LangState: FC<LangStateProps> = ({ children }) => {
    const lang = window.location.pathname.split('/')?.[1]?.toUpperCase() || Langs.EN;

    const [state, dispatch] = useReducer(langReducer, {
        language: lang
    })

    const setLanguage = (_lang: string) => {
        // localStorage.setItem('language', lang)
        // dispatch({
        //     type: LangActionType.SET_LANGUAGE,
        //     payload: _lang
        // });

        const pathname = window.location.pathname;

        if (pathname === '/') {
            window.location.assign(`/${_lang.toLowerCase()}`)
        } else if (pathname.startsWith(`/${lang.toLowerCase()}/`)) {
            const url = pathname.replace(`/${lang.toLowerCase()}/`, `/${_lang.toLowerCase()}/`)
            window.location.assign(url);
        } else if (pathname.startsWith(`/${lang.toLowerCase()}`)) {
            const url = pathname.replace(`/${lang.toLowerCase()}`, `/${_lang.toLowerCase()}`)
            window.location.assign(url);
        }
    }

    const translate = (key: string): string => {
        const { language } = state
        let langData: { [key: string]: string } = {}

        if (language === Langs.EN) {
            langData = en
        } else if (language === Langs.TR) {
            langData = tr
        } else if (language === Langs.UK) {
            langData = uk
        }

        return langData[key]
    }

    return (
        <LangContext.Provider value={{ state, dispatch: { setLanguage, translate } }}>
            {children}
        </LangContext.Provider>
    )
}

export default LangState
