import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontSize: 16,
        body1: {
            fontSize: '18px',
        },
    },

    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 8,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '& .MuiMenuItem-root': {
                    color: '#fff',
                    zIndex: 100,
                    pl: '24px !important',
                    // fontSize: '16px !important',
                    // pl: 4,
                    // '&:hover': {
                    // backgroundColor: '#F7B500',
                    // },
                },
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderColor: '#fff',
                    height: '40px',
                    borderRadius: '15px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#424A54',
                    maxHeight: '180px',
                    paddingTop: '10px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    borderRadius: '15px',
                },
            }
            // '*::-webkit-scrollbar': {
            //   width: '8px',
            // },
            // '*::-webkit-scrollbar-thumb': {
            //   background-color: rgba(0,0,0,.5),
            //   borderRadius: '4px'
            // },
            // '*::-webkit-scrollbar-thumb:hover': {
            //   backgroundColor: 'rgba(0,0,0,.6)'
            // },

        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    overflowY: 'visible',
                    maxHeight: '100px',
                    maxWidth: '500px',
                    borderRadius: '15px',
                    borderColor: '#343538',
                    backgroundColor: '#343538',
                    color: 'white',
                    '&::-webkit-scrollbar': {
                        width: '15px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#343538',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        borderRadius: '10px',
                        backgroundColor: '#555',
                        border: '5px solid #343538',
                        height: '20px',
                        '&:hover': {
                            backgroundColor: '#fff',
                        },
                    },
                },

            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    zIndex: 1,
                    backgroundColor: '#2A3038',
                    borderColor: '#F7B500',
                    borderRadius: 10,
                    color: '#fff',

                    '&.Mui-focused': {
                        backgroundColor: '#444A52',
                        color: '#F7B500',
                        // borderColor: '#444A52',
                        // borderColor: '#F7B500',
                        // color: '#fff',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ff1414',
                        borderWidth: '2px !important',
                    }
                },
            },
        },
        // MuiFilledInput: {
        //     styleOverrides: {
        //         root: {
        //             // border: 0,
        //             // borderTopLeftRadius: 8,
        //             // borderTopRightRadius: 8,
        //             // backgroundColor: '#343538',
        //             // borderColor: '#F7B500',
        //             '&:hover:not(.Mui-disabled)': {
        //                 // backgroundColor: '#343538',
        //             },
        //             '&:before': {
        //                 // borderBottom: '#343538',
        //             },
        //             '&:hover:not(.Mui-disabled):before': {
        //                 // borderBottom: '#343538',
        //             },
        //             // '&.Mui-disabled': {
        //             //   backgroundColor: '#343538',
        //             //   '&:before': {
        //             //     borderBottomStyle: 'solid'
        //             //   }
        //             // }
        //         }
        //     }
        // },
    },
});

export const selecStyles = {
    '& .MuiOutlinedInput-input': {
        backgroundColor: '#343538',
        borderRadius: '15px',
        color: '#fff',
        px: '19px',
        py: '13px',
        maxWidth: '188px',
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ff1414 !important',
            borderWidth: '2px !important',
        }
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
        maxWidth: '188px',
    },
    '&.Mui-focused': {
        borderColor: '#F7B500 !important',
        p: '26px 19px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F7B500 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#343538 !important',
    },
}

export const btnSubmitStyles = {
    backgroundColor: '#F7B500',
    '&:hover': {
        backgroundColor: '#F7B500',
    },
    color: '#191D22',
    padding: '16px 19px',
    borderRadius: '15px',
    marginTop: '20px',
    width: '100%',
    textTransform: 'none',
    '&:disabled': {
        backgroundColor: '#f7b50060',
        color: '#191D22',
    },
}
