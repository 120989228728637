import React, { FC, useContext } from "react";
import { LangContext } from "../../context/lang";

import "../../scss/_homeHero.scss";
import ButtonsDownload from "../ButtonsDownload";
import logo from "../../img/logo-small2.png";
import enPhone1 from "../../img/en/home/hero/iPhone1.png";
import ukPhone1 from "../../img/uk/home/hero/iPhone1.png";
import trPhone1 from "../../img/tr/home/hero/iPhone1.png";
import enPhone2 from "../../img/en/home/hero/iPhone2.png";
import ukPhone2 from "../../img/uk/home/hero/iPhone2.png";
import trPhone2 from "../../img/tr/home/hero/iPhone2.png";
import qr from "../../img/QR.png";
import bg from "../../img/home/hero/home_hero-bg.png";

// The Image on the right in the selected language
const images1 = {
  EN: {
    img: enPhone1,
  },
  UK: {
    img: ukPhone1,
  },
  TR: {
    img: trPhone1,
  },
};
const images2 = {
  EN: {
    img: enPhone2,
  },
  UK: {
    img: ukPhone2,
  },
  TR: {
    img: trPhone2,
  },
};

type Lang = "EN" | "UK" | "TR";

const HomeHero: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <>
      <section className="home_hero">
        <svg
          width="1920"
          height="700"
          viewBox="0 0 1920 700"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-d"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M0 468.354C194.373 613.917 353.083 711.624 595.5 697.354C1001.54 673.453 1209.18 -58.2199 1611 4.854C1851.02 42.5293 1731.5 302.354 1920 355.854"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>
        <svg
          width="1023"
          height="355"
          viewBox="0 0 1023 355"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-t"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M0 203.954C84.4307 267.167 153.371 309.597 258.67 303.4C435.043 293.021 525.237 -24.717 699.778 2.67365C804.035 19.0346 752.12 131.867 1023 155.1"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>
        <svg
          width="767"
          height="239"
          viewBox="0 0 767 239"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-m"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M-111 146.35C-50.5622 191.621 -1.21312 222.008 74.1633 217.571C200.416 210.137 264.979 -17.4178 389.92 2.19862C464.55 13.9159 427.388 94.723 767 111.362"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <div className="cont_md cont">
          <div className="content-wrap">
            <h1 className="heroZoomIn">
              {translate("home_hero_title_first")}
              <span className="heroZoomIn yellow">
                {translate("home_hero_title_second")}
              </span>
              <span className="heroZoomIn">
                {translate("home_hero_title_third")}
              </span>
            </h1>
            <p className="home-subtitle">
              <span>{translate("home_hero_subtitle")}</span>
            </p>
            <ButtonsDownload />
          </div>
        </div>

        <div className="home_back">
          <div className="home_img-wrap home_mobile1">
            <img
              src={images1[language as Lang].img}
              alt="HomeHero1"
              draggable="false"
            />
          </div>
          <div className="home_img-wrap home_mobile2">
            <img
              src={images2[language as Lang].img}
              alt="HomeHero2"
              draggable="false"
            />
          </div>
          <div className="home_img-wrap home_qr">
            <img src={qr} alt="QR" draggable="false" />
          </div>
          <div className="home_img-wrap home_hero_bg">
            <img src={bg} alt="HomeHeroBg" draggable="false" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHero;
