import React, { FC } from "react";
import HomeHero from "../components/home/HomeHero";
import HomeJoin from "../components/home/HomeJoin";
import HomePartner from "../components/home/HomePartner";
import HomeServices from "../components/home/HomeServices";
import HomeTeam from "../components/home/HomeTeam";
import TemplatePage from "./TemplatePage";
import { useParams } from "react-router-dom";

interface HomePageProps {
  translate: (key: string) => string;
}

const HomePage: FC<HomePageProps> = ({ translate }) => {
  let { locale } = useParams<{ locale: string }>();

  return (
    <TemplatePage>
      <HomeHero />
      <div className="home_bg-drops1">
        <HomeServices />
        <HomePartner />
      </div>
      <div className="home_bg-drops2">
        <HomeTeam />
        <HomeJoin />
      </div>
    </TemplatePage>
  );
};

export default HomePage;
