import React, { Fragment, FC } from "react";
import BookpayBenefits from "../components/bookpay/BookpayBenefits";
import BookpayFAQ from "../components/bookpay/BookpayFAQ";
import BookpayHero from "../components/bookpay/BookpayHero";
import BookpayJoin from "../components/bookpay/BookpayJoin";
import BookpayReviews from "../components/bookpay/BookpayReviews";
import BookpayServices from "../components/bookpay/BookpayServices";
import BookpaySlider from "../components/bookpay/BookpaySlider";
import TemplatePage from "./TemplatePage";

interface BookPayPageProps {
  translate: (key: string) => string;
}

const BookPayPage: FC<BookPayPageProps> = ({ translate }) => {
  return (
    <TemplatePage>
      <BookpayHero />
      <div className="bp_container-vector">
        <svg
          width="372"
          height="862"
          viewBox="0 0 372 862"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-d"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.25"
            d="M372 1.35142C194.586 6.62123 40.3087 39.4027 72.1779 141.098C150.854 392.156 -100.548 562.889 52.2469 740.282C139.704 841.819 272.44 795.185 372 860.38"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <BookpaySlider />
        <BookpayServices />
      </div>
      <BookpayBenefits />
      <BookpayReviews />
      <BookpayJoin />
      <BookpayFAQ />
    </TemplatePage>
  );
};

export default BookPayPage;
