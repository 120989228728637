import React, { FC, useContext, useState, useEffect, useRef } from "react";
import LangState, { LangContext } from "../context/lang";
import Slider from "react-slick";

// import "../scss/_homeSlider.scss";
// import { IImagesComponents } from "../store/interfaces/images";
// import { url } from "inspector";
// import { red } from "@mui/material/colors";
// import lIn from "../img/home/team/linkedin.png";
import { ISlidersTeamProps } from "./interfaces/ISlidersTeamProps";

// import linkImg from "../img/home/team/linkedin.png";

interface ISlidersArray {
  arr: Array<ISlidersTeamProps>;
  slide?: number;
  slide1?: number;
  slide2?: number;
  slide3?: number;
  slide4?: number;
  direction: boolean;
  speed?: number;
}

type Lang = "EN" | "UK" | "TR";

const SliderTeam: FC<ISlidersArray> = ({
  arr,
}: ISlidersArray) => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);
  const [currentSlideIndex, setCurrentSlideIndex] = useState();

  // Main slider display settings
  let settings = {
    infinite: false,
    speed: 500,
    arrows: true,
    // draggable: true,
    // swipe: true,
    slidesToShow: 5,
    slidesToScroll: 4,
    // adaptiveHeight: true,
    responsive: [
      // {
      //   breakpoint: 1639,
      //   settings: {
      //     slidesToShow: slide1
      //   }
      // },
      {
        breakpoint: 1439,
        settings: {
          // slidesToShow: slide2,
          // speed: 30000
          slidesToShow: 5,
          slidesToScroll: 4,

          // infinite: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          // slidesToShow: slide3
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          // slidesToShow: slide4,
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // Scroll
  //  const slider = useRef<any>(null);

  //  function scroll(e) {
  //    if (slider === null) return 0;

  //    e.wheelDelta > 0 ? slider.current.slickNext() : slider.current.slickPrev();
  //  }

  //  useEffect(() => {
  //    window.addEventListener("wheel", scroll, true);

  //    return () => {
  //      window.removeEventListener("wheel", scroll, true);
  //    };
  //  }, []);

  return (
    <div className="home_slider_team-styles">
      <Slider {...settings}>
        {/* <Slider {...settings} ref={slider}> */}
        {arr.map((item, index) => (
          <div key={index}>
            <div className="home_slider_team-grid">
              <div className="item">
                <div className="img-wrap">
                  <a
                    href={item.linkedin}
                    className="linkedin_team"
                    target="blank"
                  >
                    {" "}
                    <img
                      src={item.img.src}
                      alt="slider_photo"
                      draggable="false"
                    />
                  </a>
                  {/* <img src={linkImg} className="linkImg" alt="linkimg" /> */}
                </div>
              </div>
              <div className="item">
                <div className="item-content">
                  <h6 className="item-name">{translate(item.name)}</h6>
                  <div className="item-position">
                    <p id="item-home-position">{translate(item.position)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderTeam;
