import React, { FC, useContext } from "react";
import { LangContext } from "../../context/lang";
import Sliders from "../Sliders";

import Img1En from "../../img/en/insurance/slider/enInsSlider1.png";
import Img2En from "../../img/en/insurance/slider/enInsSlider2.png";
import Img3En from "../../img/en/insurance/slider/enInsSlider3.png";
import Img1Uk from "../../img/uk/insurance/slider/ukInsSlider1.png";
import Img2Uk from "../../img/uk/insurance/slider/ukInsSlider2.png";
import Img3Uk from "../../img/uk/insurance/slider/ukInsSlider3.png";
import Img1Tr from "../../img/tr/insurance/slider/trInsSlider1.png";
import Img2Tr from "../../img/tr/insurance/slider/trInsSlider2.png";
import Img3Tr from "../../img/tr/insurance/slider/trInsSlider3.png";

import "../../scss/_insuranceSlider.scss";
import { ISlidersProps } from "../interfaces/ISlidersProps";

// Images for Grid. src
const images = {
  EN: {
    img1: Img1En,
    img2: Img2En,
    img3: Img3En,
  },
  UK: {
    img1: Img1Uk,
    img2: Img2Uk,
    img3: Img3Uk,
  },
  TR: {
    img1: Img1Tr,
    img2: Img2Tr,
    img3: Img3Tr,
  },
};

type Lang = "EN" | "UK" | "TR";

const InsuaranceSlider: FC = () => {
  const {
    state: { language },
  } = useContext(LangContext);

  // Array to fill with slider content
  const sliders = [
    {
      name: "insuranse_slider_title1",
      content: "insuranse_slider_text1",
      content2: "insuranse_slider_text1_2",
      img: {
        src: images[language as Lang].img1,
        alt: "mtlp",
      },
    },
    {
      name: "insuranse_slider_title2",
      content: "insuranse_slider_text2",
      content2: "insuranse_slider_text2_2",
      img: {
        src: images[language as Lang].img2,
        alt: "casco",
      },
    },
    {
      name: "insuranse_slider_title3",
      content: "insuranse_slider_text3",
      content2: "insuranse_slider_text3_2",
      img: {
        src: images[language as Lang].img3,
        alt: "Tanks",
      },
    }
  ] as ISlidersProps[];

  return (
    <section className="insurance_slider">
      <Sliders arr={sliders}
        heading="insurance"
        title="insurance_slider_header"
      />
    </section>
  );
};

export default InsuaranceSlider;
