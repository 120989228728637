import React from 'react';
import "../../../scss/_autoServicePartner.scss";


interface InputProps {
    id: string;
    label: string;
    validationError?: boolean;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    value: string;
}

const Textarea: React.FC<InputProps> = ({
    id,
    label,
    value,
    onChange,
}) => {
    return (
        <div key={id} className="form-group message">
            <label className="form-label message" htmlFor={id}>{label}</label>

            <textarea
                title="Message"
                id={id}
                className="form-control"
                aria-describedby="inputHelp"
                value={value}
                onChange={onChange}
                rows={7}
            />
        </div>
    );
};

export default Textarea;
