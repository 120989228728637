import React, { useContext, useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import PlacesAutocomplete from 'react-places-autocomplete';
import { LangContext } from '../../../context/lang';
import { IAddress } from '../interfaces/IServicePoint';
import "../../../scss/_autoServicePartner.scss";

interface InputProps {
  id: string;
  label: string;
  error: boolean;
  errorMessage: string;
  formSubmitted: boolean;
  value: IAddress;
  onChange: (id: string, value: IAddress) => void;
}

const InputAutocomplete: React.FC<InputProps> = ({
  id,
  label,
  error,
  errorMessage,
  formSubmitted,
  value,
  onChange
}) => {
  const { state: { language } } = useContext(LangContext);

  const [address, setAddress] = useState(value?.name?.[language.toLowerCase()] || '');

  const handleOnChange = (value: string) => {
    setAddress(value)
  }

  const handleAddressSelect = (address, placeId) => {
    setAddress(address);
    const value: IAddress = {
      name: {
        [language.toLowerCase()]: address
      },
      placeId,
    }
    onChange(id, value);
  }

  let className = "form-control";
  if (formSubmitted && error) {
    className += " input-error";
  }

  return (
    <div key={id} className="form-group">
      <label className="form-label" htmlFor={id}>{label}</label>

      <PlacesAutocomplete
        value={address}
        onChange={handleOnChange}
        onSelect={handleAddressSelect}
        searchOptions={{
          componentRestrictions: { country: "ua" }
        }}
      >
        {(opts) => {
          const { getInputProps, suggestions, getSuggestionItemProps } = opts
          const padding = suggestions.length ? { padding: '8px' } : { padding: '0px' }

          return (
            <>
              <TextareaAutosize
                {...getInputProps({
                  id,
                  className,
                })}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  zIndex: 10,
                  backgroundColor: '#343538',
                  borderRadius: '20px',
                  boxShadow: 1,
                  maxHeight: '200px',
                  overflowY: 'scroll',
                  ...padding,
                }}
              >
                {suggestions.map((suggestion, index) => {
                  const suggestionProps = getSuggestionItemProps(suggestion);

                  return (
                    <Box
                      {...suggestionProps}
                      key={index}
                    >
                      <MenuItem
                        sx={{
                          cursor: 'pointer',
                          userSelect: 'auto',
                          backgroundColor: 'transparent !important',
                          height: 'auto',
                        }}
                      >
                        {suggestion.description}
                      </MenuItem>
                    </Box>
                  );
                })}
              </Box>
            </>
          )
        }}
      </PlacesAutocomplete>
      {formSubmitted && error && <div className="input_error">{errorMessage}</div>}
    </div>
  );
};

export default InputAutocomplete;
