import React, { FC, useContext, useState } from "react";
import { LangContext } from "../context/lang";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { matchPath } from "react-router";

import logo from "../img/logo.png";
import gb from "../img/GB.png";
import ukraine from "../img/Ukraine.png";
import turkey from "../img/Turkey.png";

import ButtonsDownload from "./ButtonsDownload";
import Social from "./Social";

import "../scss/_navibar.scss";
import Lang from "./interfaces/enums/Langs";

const NaviBar: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  const [activeMenu, setActiveMenu] = useState(
    window.location.pathname.split("/")[2]?.toLowerCase() || ""
  );
  const [hamburgerActive, setHamburgerActive] = useState<boolean>(false);

  const lang = language;
  // Hamburger
  const handlerClick = (e) => {
    // e.preventDefoult()
    let menuOpen = document.getElementById("menu");
    let menuIcon = document.getElementById("nav-icon");
    let body = document.querySelector("body");

    if (menuIcon) {
      menuIcon.addEventListener("click", function (e) {
        menuIcon?.classList.toggle("open");
        body?.classList.toggle("scroll-hidden");

        if (this.classList.contains("open")) {
          menuOpen?.classList.add("is-active");
        } else {
          menuOpen?.classList.remove("is-active");
        }
      });
    }
  };

  return (
    <header id="top">
      <div className="cont_lg cont_main">
        <div className="row">
          {/* Logo */}
          <div className="item">
            <a href="/" className="logo">
              <img src={logo} alt="logo" draggable="false" />
            </a>
          </div>
          {/* Navigation */}
          <div className="item">
            <nav
              // className="nav"
              className={hamburgerActive ? "nav is-active" : "nav"}
              id="menu"
            // onClick={() => setHamburgerActive(false)}
            >
              {/* <div onClick={(e) => e.stopPropagation()}> */}
              <ul>
                <li
                  className={
                    activeMenu === "home" || activeMenu === ""
                      ? "nav-visible"
                      : ""
                  }
                  id="nav-home"
                >
                  <NavLink
                    to={`/${lang.toLowerCase()}`}
                    onClick={() => setActiveMenu("home")}
                  >
                    {translate("nav_home")}
                  </NavLink>
                </li>
                <li className={activeMenu === "bookpay" ? "nav-visible" : ""}>
                  <NavLink
                    to={`/${lang.toLowerCase()}/bookpay`}
                    onClick={() => setActiveMenu("bookpay")}
                  >
                    {translate("nav_book_&_pay")}
                  </NavLink>
                </li>
                <li
                  className={activeMenu === "insurance" ? "nav-visible" : ""}
                >
                  <NavLink
                    to={`/${lang.toLowerCase()}/insurance`}
                    onClick={() => setActiveMenu("insurance")}
                  >
                    {translate("nav_insurance")}
                  </NavLink>
                </li>
                <li className={activeMenu === "parking" ? "nav-visible" : ""}>
                  <NavLink
                    to={`/${lang.toLowerCase()}/parking`}
                    onClick={() => setActiveMenu("parking")}
                  >
                    {translate("nav_parking")}
                  </NavLink>
                </li>
                <li className={activeMenu === "service" ? "nav-visible" : ""}>
                  <NavLink
                    to={`/${lang.toLowerCase()}/service`}
                    onClick={() => setActiveMenu("service")}
                  >
                    {translate("nav_car_srvc_partner")}
                  </NavLink>
                </li>
                <li className="btn_mob">
                  <div className="item">
                    <button
                      className={lang === Lang.EN ? "btn-color" : "btn-lng"}
                      onClick={() => setLanguage(Lang.EN)}
                    >
                      <img src={gb} alt="flag_en" draggable="false" />
                    </button>
                    <button
                      className={lang === Lang.UK ? "btn-color" : "btn-lng"}
                      onClick={() => setLanguage(Lang.UK)}
                    >
                      <img src={ukraine} alt="flag_uk" draggable="false" />
                    </button>
                    <button
                      className={lang === Lang.TR ? "btn-color" : "btn-lng"}
                      onClick={() => setLanguage(Lang.TR)}
                    >
                      <img src={turkey} alt="flag_tr" draggable="false" />
                    </button>
                  </div>
                  <div className="btn_mob-download">
                    <div className="grid-bottom">
                      <div className="item-bottom">
                        <ButtonsDownload />
                      </div>
                      <div className="item-bottom social">
                        <Social />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              {/* </div> */}
            </nav>
          </div>
          {/* flags - language selection */}
          <div className="item last">
            <button
              className={lang === Lang.EN ? "btn-color" : "btn-lng"}
              onClick={() => setLanguage(Lang.EN)}
            >
              <img src={gb} alt="flag_en" draggable="false" />
            </button>
            <button
              className={lang === Lang.UK ? "btn-color" : "btn-lng"}
              onClick={() => setLanguage(Lang.UK)}
            >
              <img src={ukraine} alt="flag_uk" draggable="false" />
            </button>
            <button
              className={lang === Lang.TR ? "btn-color" : "btn-lng"}
              onClick={() => setLanguage(Lang.TR)}
            >
              <img src={turkey} alt="flag_tr" draggable="false" />
            </button>
          </div>
          <div></div>
        </div>
      </div>

      {/* hamburger */}

      <a
        id="nav-icon"
        // className="hamburger"
        className={hamburgerActive ? "hamburger open" : "hamburger"}
        // onClick={(e) => handlerClick(e)}
        onClick={() => setHamburgerActive(!hamburgerActive)}
      >
        <span></span>
        <span></span>
        <span></span>
      </a>
    </header>
  );
};

export default NaviBar;
