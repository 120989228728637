import React, { FC, useState, useContext } from "react";

// Using an accardion from the mui library
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

import "../../scss/_parkingFAQ.scss";
import LangState, { LangContext } from "../../context/lang";

const ParkingFAQ: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Closing panels that are not in use
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section className="parking_faq">
      <svg
        width="1920"
        height="1322"
        viewBox="0 0 1920 1322"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-v"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M1911.87 1321C1949.01 1036.93 1853 609.517 1743.21 386.622C1629.26 155.297 1440.67 31.6197 1207.54 50.983C974.413 70.3462 915.693 366.955 719.147 290.276C591.509 240.48 602.331 66.2296 475.481 13.9428C237.706 -84.0671 -71 406.717 -71 406.717"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <div className="cont cont_md">
        <div className="">
          <h6>{translate("parking_faq_sub")}</h6>
          <h2>{translate("parking_faq_title")}</h2>
        </div>
        <div className="accordions">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel1")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className="faq-title">
                {translate("parking_faq_question1")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("parking_faq_answer1")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel2")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className="faq-title">
                {translate("parking_faq_question2")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("parking_faq_answer2")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel3")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography className="faq-title">
                {translate("parking_faq_question3")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("parking_faq_answer3")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel4")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel4-content"
              id="panel3-header"
            >
              <Typography className="faq-title">
                {translate("parking_faq_question4")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("parking_faq_answer4")}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default ParkingFAQ;
