import React, { FC, useContext, useEffect,  useState } from "react";
import { NavLink } from "react-router-dom";
import LangState, { LangContext } from "../context/lang";
import logo from "../img/logo-footer.png";
import Social from "./Social";
import { matchPath } from "react-router";
import Lang from "./interfaces/enums/Langs";

import "../scss/_footer.scss";

const Footer: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  const [activeMenu, setActiveMenu] = useState(window.location.pathname.split('/')[2]?.toLowerCase() || '');

  const lang = language;

  return (
    <footer className="footer">
      <div className="cont_mx cont-footer">
        <div className="top center">
          <div className="grid">
            <div className="item first">
              <img className="footer-logo" src={logo} alt="logo" draggable="false"/>
              <div className="footer-sub">
                <p>{translate("footer_underlogo")}</p>
              </div>
            </div>
            <div className="item second">
              <p className="footer-title">{translate("footer_title1")}</p>
              <ul>
                <li>
                  <NavLink to={`/${lang.toLowerCase()}/bookpay`}>{translate("nav_book_&_pay")}</NavLink>
                </li>
                <li>
                  <NavLink to={`/${lang.toLowerCase()}/insurance`}>
                    {translate("nav_insurance")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${lang.toLowerCase()}/parking`}>{translate("nav_parking")}</NavLink>
                </li>
              </ul>
            </div>
            <div className="item third">
              <p className="footer-title">{translate("footer_title2")}</p>
              <ul>
                <li>
                  <NavLink to={`/${lang.toLowerCase()}/insurance`}>
                    {translate("nav_insurance")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to={`/${lang.toLowerCase()}/parking`}>{translate("nav_parking")}</NavLink>
                </li>
              </ul>
            </div>
            <div className="item fourth">
              <div className="social-section">
                <Social />
              </div>
            </div>
          </div>
        </div>

        <div className="cont cont_mx">
          <div className="bottom">
            <p>
              {translate("footer_bottom")} {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
