// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import ENV_CONFIG from './.env.json'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

console.log('ENV_CONFIG', ENV_CONFIG)

const firebaseConfig = {
    apiKey: ENV_CONFIG.REACT_APP_API_KEY,
    authDomain: ENV_CONFIG.REACT_APP_AUTH_DOMAIN,
    databaseURL: ENV_CONFIG.REACT_APP_DATABASE_URL,
    projectId: ENV_CONFIG.REACT_APP_PROJECT_ID,
    storageBucket: ENV_CONFIG.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: ENV_CONFIG.REACT_APP_MESSAGING_SENDER_ID,
    appId: ENV_CONFIG.REACT_APP_APP_ID,
    measurementId: ENV_CONFIG.REACT_APP_MEASUREMENT_ID
};
const firebaseBacData = {
    locationId: ENV_CONFIG.REACT_APP_BAC_DATA_LOCATION_ID,
    apiKey: ENV_CONFIG.REACT_APP_BAC_DATA_API_KEY,
    authDomain: ENV_CONFIG.REACT_APP_BAC_DATA_AUTH_DOMAIN,
    databaseURL: ENV_CONFIG.REACT_APP_BAC_DATA_DATABASE_URL,
    projectId: ENV_CONFIG.REACT_APP_BAC_DATA_PROJECT_ID,
    storageBucket: ENV_CONFIG.REACT_APP_BAC_DATA_STORAGE_BUCKET,
    persistence: ENV_CONFIG.REACT_APP_BAC_DATA_PERSISTENCE
};

const initApp = (config, name = '[DEFAULT]') => {
    let app;
    try {
        app = initializeApp(config, name);
    } catch (e: any) {
        console.log(`${name} Firebase.initializeApp`, e.message);
        app = getApp(name);
    }
    return app;
}

const beepSaas = initApp(firebaseConfig);
const bacData = initApp(firebaseBacData, 'bac-data');


const initDatabase = (app) => getDatabase(app);
const initAnalytics = (app) => getAnalytics(app);
const initAuth = (app) => getAuth(app);
const initStorage = (app) => getStorage(app);
const initFunctions = (app, region = '') => getFunctions(app, region);

export const RealtimeDB = initDatabase(beepSaas);
export const Analytics = initAnalytics(beepSaas);
export const Functions = initFunctions(beepSaas);
export const RealtimeDB2 = initDatabase(bacData);
export const Auth = initAuth(beepSaas);
export const Storage = initStorage(beepSaas);
export const Functions3 = initFunctions(beepSaas, 'europe-west1');
export const Firestore = getFirestore(beepSaas)

