import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";

import "../../scss/_parkingPartners.scss";

import Img1 from "../../img/parking/partners/partners1.png";
import Img2 from "../../img/parking/partners/partners2.png";
import Img3 from "../../img/parking/partners/partners3.png";
import Img4 from "../../img/parking/partners/partners4.png";

const ParkingPartners: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <section className="parking_partners">
      <div className="cont cont_md">
        <div className="grid-container">
          <div className="item1">
            <h6>{translate("parking_partners_name")}</h6>
            <h2 id="feature">{translate("parking_partners_bigtitle")}</h2>
          </div>
          <div className="item-2">
            <div className="grid">
              <div className="first">
                <div className="img-wrap">
                  <img src={Img1} alt="partners1" draggable="false"/>
                </div>
                <div className="text-cont">
                  <div className="partners-title">
                    <p> {translate("parking_partners_title1")} </p>
                  </div>
                </div>
              </div>
              <div className="second">
                <div className="img-wrap">
                  <img src={Img2} alt="partners2" draggable="false"/>
                </div>
                <div className="text-cont">
                  <div className="partners-title">
                    <p> {translate("parking_partners_title2")} </p>
                  </div>
                </div>
              </div>
              <div className="third">
                <div className="img-wrap">
                  <img src={Img3} alt="partners3" draggable="false"/>
                </div>
                <div className="text-cont">
                  <div className="partners-title">
                    <p> {translate("parking_partners_title3")} </p>
                  </div>
                </div>
              </div>
              <div className="forth">
                <div className="img-wrap">
                  <img src={Img4} alt="partners4" draggable="false"/>
                </div>
                <div className="text-cont">
                  <div className="partners-title">
                    <p> {translate("parking_partners_title4")} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParkingPartners;
