import React, { FC, useContext, useEffect } from "react";
import LangState, { LangContext } from "../../context/lang";
import { observer } from "mobx-react";

import ImgTitleEn from "../../img/en/insurance/reviews/enInsRewTitle.png";
import ImgTitleUk from "../../img/uk/insurance/reviews/ukInsRewTitle.png";
// import ImgTitleUk from "../../img/0.png";
import ImgTitleTr from "../../img/tr/insurance/reviews/trInsRewTitle.png";
import heart from "../../img/love.png";

import StoreFeedbacksBookpay from "../../store/feedbacks-bookpay.store";

import "../../scss/_bookpayReviews.scss";
import { Languages } from "../../store/interfaces/enums";
import Reviews from "../Reviews";

// Picture of Title
const images = {
  en: {
    imgTitle: ImgTitleEn,
  },
  uk: {
    imgTitle: ImgTitleUk,
  },
  tr: {
    imgTitle: ImgTitleTr,
  },
};

const BookpayReviews: FC = observer(() => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Hook for work with store and DataBase of firebase with mobx
  useEffect(() => {
    StoreFeedbacksBookpay.onFeedbacks();

    return function cleanup() {
      StoreFeedbacksBookpay.offFeedbacks();
    };
  }, []);

  return (
    <section className="bookpay_reviews">
      <svg
        width="1920"
        height="617"
        viewBox="0 0 1920 517"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-d"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-0.131109 54.2798C72.8667 23.6597 138.922 -15.7573 252.974 8.77596C559.696 74.7535 522.606 197.254 843.344 367.346C1164.08 537.438 1477.95 559.034 1728.73 441.717C1790.24 412.942 1855.67 372.544 1920 324"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="1023"
        height="546"
        viewBox="0 0 1023 546"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-t"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-252 46.6464C-182.493 19.7599 -115.367 -15.8828 -32.908 12.0385C188.849 87.1277 114.908 204.118 310.285 380.322C505.662 556.526 749.168 588.997 992.337 484.917C1051.98 459.388 1118.96 422.775 1188 378.249"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="767"
        height="546"
        viewBox="0 0 375 546"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-m"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-361 46.6464C-291.493 19.7599 -224.367 -15.8828 -141.908 12.0385C79.8492 87.1277 5.90807 204.118 201.285 380.322C396.662 556.526 640.168 588.997 883.337 484.917C942.981 459.388 1009.96 422.775 1079 378.249"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      {/* <div className="reviews-vector"> */}
      <div className="cont cont_md">
        <div className="">
          <h6>{translate("bookpay_review_title")}</h6>
          <div className="img-wrap">
            <h2>{translate("insurance_review_subtitle1")}</h2>{" "}
            {language &&
              (language === "UK" ? (
                <div></div>
              ) : (
                <img
                  src={heart}
                  alt="insurance_review_title"
                  draggable="false"
                />
              ))}{" "}
            <h2>{translate("insurance_review_subtitle2")}</h2>
          </div>
        </div>
        <Reviews item={StoreFeedbacksBookpay.list} />
      </div>
      {/* </div> */}
    </section>
  );
});

export default BookpayReviews;
