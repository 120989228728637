import React, { FC, useContext } from "react";
import { LangContext } from "../../context/lang";
import enmain from "../../img/en/bp/hero/en_bp_hero.png";
import ukmain from "../../img/uk/bp/hero/uk_bp_hero.png";
import trmain from "../../img/tr/bp/hero/tr_bp_hero.png";
import bg from "../../img/bookpay/hero/bookpay_hero_bg.png";
import tools1 from "../../img/bookpay/hero/tools1.png";
import tools2 from "../../img/bookpay/hero/tools2.png";
import tools3 from "../../img/bookpay/hero/tools3.png";
import tools4 from "../../img/bookpay/hero/tools4.png";
import find from "../../img/insurance/hero/find.png";
// import { useTranslation } from "react-i18next";
// import logo from "../img/logo.png";
// import { NavLink } from "react-router-dom";
// import cookies from 'js-cookie'

import "../../scss/_bookpayHero.scss";

// The Image on the right in the selected language
const images = {
  EN: {
    img: enmain,
  },
  UK: {
    img: ukmain,
  },
  TR: {
    img: trmain,
  },
};

type Lang = "EN" | "UK" | "TR";

const BookpayHero: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <>
      <section className="bookpay_hero">
        <div className="cont_md cont">
          <div className="content-wrap">
            <h1 className="heroZoomIn yellow">
              {translate("bookpay_hero_title_yellow")}
            </h1>
            <h1 className="heroZoomIn">
              {translate("bookpay_hero_title_white")}
            </h1>
            <a href="https://app.beep.club/website" target="_blank">
              <button className="btn">{translate("bookpay_hero_btn")}</button>
            </a>
          </div>
        </div>
        <p className="bp_bottom_subtitle">
          <img src={find} alt="Find" draggable="false" />
          {translate("hero_text1")}
          <a href="https://apps.apple.com/app/id1505288985" target="_blank">
            {" "}
            AppStore{" "}
          </a>{" "}
          {translate("hero_text2")}
          <a
            href="https://play.google.com/store/apps/details?id=com.bac.client"
            target="_blank"
          >
            {" "}
            GooglePlay
          </a>
          {translate("hero_text3")}
        </p>

        <div className="bp_back">
          <div className="bp_img-wrap bp_mobile">
            <img
              src={images[language as Lang].img}
              alt="BPHero"
              draggable="false"
            />
          </div>
          <div className="bp_img-wrap bp_tools1">
            <img src={tools1} alt="tools1" draggable="false" />
          </div>
          <div className="bp_img-wrap bp_tools2">
            <img src={tools2} alt="tools2" draggable="false" />
          </div>
          <div className="bp_img-wrap bp_tools3">
            <img src={tools3} alt="tools3" draggable="false" />
          </div>
          <div className="bp_img-wrap bp_tools4">
            <img src={tools4} alt="tools4" draggable="false" />
          </div>
          <div className="bp_img-wrap bp_map">
            <img src={bg} alt="BPHeroBg1" draggable="false" />
          </div>
        </div>
      </section>
    </>
  );
};

export default BookpayHero;
