import React, { FC, useContext, useEffect } from "react";
import LangState, { LangContext } from "../../context/lang";
import { observer } from "mobx-react";

import heart from "../../img/love.png";

import StoreFeedbacks from "../../store/feedbacks.store";
import { Languages } from "../../store/interfaces/enums";
import Reviews from "../Reviews";

import "../../scss/_insuranceReviews.scss";

const InsuranceReviews: FC = observer(() => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Hook for work with store and DataBase of firebase wit mobx
  useEffect(() => {
    StoreFeedbacks.onFeedbacks();

    return function cleanup() {
      StoreFeedbacks.offFeedbacks();
    };
  }, []);

  return (
    <section className="insurance_reviews">
      <svg
        width="1920"
        height="726"
        viewBox="0 0 1920 726"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-d"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-0.000439798 61.4702C92.7018 25.6115 182.228 -21.9255 292.204 15.3134C587.963 115.46 489.347 271.49 749.923 506.495C1010.5 741.5 1335.26 784.807 1659.58 645.995C1739.13 611.947 1828.46 563.115 1920.54 503.731"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="1023"
        height="546"
        viewBox="0 0 1023 546"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-t"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-252 46.6464C-182.493 19.7599 -115.367 -15.8828 -32.908 12.0385C188.849 87.1277 114.908 204.118 310.285 380.322C505.662 556.526 749.168 588.997 992.337 484.917C1051.98 459.388 1118.96 422.775 1188 378.249"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="767"
        height="546"
        viewBox="0 0 767 546"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-m"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M-361 46.6464C-291.493 19.7599 -224.367 -15.8828 -141.908 12.0385C79.8492 87.1277 5.90807 204.118 201.285 380.322C396.662 556.526 640.168 588.997 883.337 484.917C942.981 459.388 1009.96 422.775 1079 378.249"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <div className="reviews-vector">
        <div className="cont cont_md">
          <div className="">
            <h6>{translate("insurance_review_title")}</h6>
            <div className="img-wrap">
              <h2>{translate("insurance_review_subtitle1")}</h2>{" "}
              {language &&
                (language === "UK" ? (
                  <div></div>
                ) : (
                  <img
                    src={heart}
                    alt="insurance_review_title"
                    draggable="false"
                  />
                ))}{" "}
              <h2>{translate("insurance_review_subtitle2")}</h2>
            </div>
          </div>
          <Reviews item={StoreFeedbacks.list} />
        </div>
      </div>
    </section>
  );
});

export default InsuranceReviews;
