import { IAddress, ServiceCategory } from '../interfaces/IServicePoint';

export enum AutoType {
    MOTO = 'MOTO',
    CAR = 'CAR',
    PASSENGER = 'PASSENGER',
    FREIGHT = 'FREIGHT',
    TRAILER = 'TRAILER',
    AGRICULTURAL = 'AGRICULTURAL',
    SPECIAL = 'SPECIAL',
}

export interface ISelectValue {
    id: string;
    value: string;
}

export interface ICheckboxItem {
    id: string;
    value: string;
    // icon: any;
}

export const serviceCategoryList = (): ICheckboxItem[] => [
    { id: ServiceCategory.REPAIR, value: 'car_services_main_REPAIR', /* icon: IconCarRepair */ },
    { id: ServiceCategory.TYRE, value: 'car_services_main_TYRE', /* icon: IconWheel */ },
    { id: ServiceCategory.WASH, value: 'car_services_main_WASH', /* icon: IconCarWash */ },
    { id: ServiceCategory.BODY_REPAIR, value: 'car_services_main_BODY_REPAIR', /* icon: IconBodyRepair */ },
    { id: ServiceCategory.INSPECTION, value: 'car_services_main_INSPECTION', /* icon: IconInspection */ },
];

export const carTypesList = (): ISelectValue[] => [
    { id: AutoType.CAR, value: 'car_services_type_CAR' },
    { id: AutoType.MOTO, value: 'car_services_type_MOTO' },
    { id: AutoType.PASSENGER, value: 'car_services_type_PASSENGER' },
    { id: AutoType.FREIGHT, value: 'car_services_type_FREIGHT' },
    { id: AutoType.TRAILER, value: 'car_services_type_TRAILER' },
    { id: AutoType.AGRICULTURAL, value: 'car_services_type_AGRICULTURAL' },
    { id: AutoType.SPECIAL, value: 'car_services_type_SPECIAL' },
];

export const daysList = (): ISelectValue[] => [
    { id: 'mon', value: 'car_services_days_mon' },
    { id: 'tue', value: 'car_services_days_tue' },
    { id: 'wed', value: 'car_services_days_wed' },
    { id: 'thu', value: 'car_services_days_thu' },
    { id: 'fri', value: 'car_services_days_fri' },
    { id: 'sat', value: 'car_services_days_sat' },
    { id: 'sun', value: 'car_services_days_sun' },
];

export const additionalServiceList = (): ISelectValue[] => [
    { id: 'wc', value: 'car_services_additional_wc' },
    { id: 'wifi', value: 'car_services_additional_wifi' },
    { id: 'coffee', value: 'car_services_additional_coffee' },
    { id: 'cafe', value: 'car_services_additional_cafe' },
    { id: 'disabled', value: 'car_services_additional_disabled' },
    { id: 'parking', value: 'car_services_additional_parking' },
    { id: 'tv', value: 'car_services_additional_tv' },
    { id: 'master', value: 'car_services_additional_master' },
    { id: 'replacement', value: 'car_services_additional_replacement' },
    { id: 'delivery', value: 'car_services_additional_delivery' },
    { id: 'lounge', value: 'car_services_additional_lounge' },
    { id: 'card', value: 'car_services_additional_card' },
    { id: 'desk', value: 'car_services_additional_desk' },
    { id: 'cash', value: 'car_services_additional_cash' },
    { id: 'ac', value: 'car_services_additional_ac' },
    { id: 'shop', value: 'car_services_additional_shop' },
    { id: '24_hours', value: 'car_services_additional_24_hours' },
    { id: 'pc', value: 'car_services_additional_pc' },
    { id: 'kids', value: 'car_services_additional_kids' },
];

export const UK_CODE = '+380';

export interface OptionType {
    id: string;
    value: string;
}

export interface FieldType {
    id: string;
    type: string;
    value: string | string[] | IAddress;
    options?: OptionType[];
    time?: FieldType[];
}

export interface IFieldState {
    id: string;
    label: string;
    type: string;
    key?: string;
    options?: ISelectValue[];
    time?: any;
    value: string | string[] | IAddress;
    placeholder?: string;
    isValid?: boolean;
    errorMessage?: string;
    isTouched?: boolean;
    validations?: {
        maxLength?: number;
        isEmpty?: boolean;
        minLength?: number;
        isPhoneNumber?: boolean;
        errorMessage?: string;
    };
}

export const initialFields: IFieldState[] = [
    {
        id: 'authPhone',
        label: 'сar_srvc_partner_field_phone',
        type: 'text',
        value: '',
        placeholder: UK_CODE,
        validations: {
            maxLength: 13,
            isEmpty: true,
            isPhoneNumber: true,
            errorMessage: 'сar_srvc_partner_error_phone'
        }
    },
    {
        id: 'type',
        label: 'сar_srvc_partner_field_category',
        type: 'select',
        options: serviceCategoryList(),
        value: [],
        validations: {
            isEmpty: true,
        }
    },
    {
        id: 'name',
        label: 'сar_srvc_partner_field_name',
        type: 'text',
        value: '',
        validations: {
            isEmpty: true,
        }
    },
    {
        id: 'address',
        label: 'сar_srvc_partner_field_address',
        type: 'text',
        value: '',
        validations: {
            isEmpty: true,
        }
    },
    {
        id: 'phone',
        label: 'сar_srvc_partner_field_contacts',
        type: 'text',
        value: '',
        placeholder: UK_CODE,
        validations: {
            maxLength: 13,
            isEmpty: true,
            isPhoneNumber: true,
            errorMessage: 'сar_srvc_partner_error_phone'
        }
    },
    {
        id: 'vehicleType',
        label: 'сar_srvc_partner_field_types',
        type: 'select',
        options: carTypesList(),
        value: [],
        validations: {
            isEmpty: true,
        }
    },
    {
        id: 'vehicleBrand',
        label: 'сar_srvc_partner_field_brands',
        type: 'select',
        options: [],
        value: [],
        validations: {
            isEmpty: true,
        }
    },
    {
        id: 'description',
        label: 'сar_srvc_partner_field_descr',
        type: 'textarea',
        value: '',
        validations: {
            maxLength: 600,
        }
    },
    {
        id: 'workingTime',
        label: 'сar_srvc_partner_field_days',
        type: 'days',
        options: daysList(),
        value: [],
        validations: {
            isEmpty: true,
        },
        time: [
            {
                id: 'workingTime.time.from',
                label: 'сar_srvc_partner_field_hours_from',
                type: 'time',
                isValid: false,
                value: '',
                validations: {
                    isEmpty: true,
                },
            },
            {
                id: 'workingTime.time.to',
                label: 'сar_srvc_partner_field_hours_to',
                type: 'time',
                isValid: false,
                value: '',
                validations: {
                    isEmpty: true,
                },
            },
        ]
    },
    {
        id: 'additionalServices',
        label: 'сar_srvc_partner_field_additional',
        type: 'select',
        options: additionalServiceList(),
        value: [],
    },
];
