import React, {
  FC,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
// import emailjs from '@emailjs/browser';
// import emailjs from "emailjs-com";
import btn from "../../img/parking/feedback/paper-plane.png";

import "../../scss/_parkingFeedback.scss";
import { LangContext } from "../../context/lang";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer, toast } from "react-toastify";
// import { formControlClasses } from "@mui/material";
// import { isDisabled } from "@testing-library/user-event/dist/utils";
// import { setConstantValue } from "typescript";

if (typeof window !== "undefined") {
  injectStyle();
}

// custom hook for validation
const useValidation = (value, validations) => {
  const [isEmpty, setIsEmpty] = useState(true); // field empty check
  const [minLengthError, setMinLengthError] = useState(false); // checking for input min length
  // const [maxLengthError, setMaxLengthError] = useState(false); // checking for input max length
  const [emailError, setEmailError] = useState(false); // match a string with a regular expression
  const [inputValid, setInputValid] = useState(false); // general validity check

  // Test switch
  useEffect(() => {
    for (const validation in validations) {
      switch (validation) {
        case "minLenght":
          value.lenght < validations[validation]
            ? setMinLengthError(true)
            : setMinLengthError(false);
          break;
        case "isEmpty":
          value ? setIsEmpty(false) : setIsEmpty(true);
          break;
        case "isEmail":
          const re =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
          re.test(String(value).toLowerCase())
            ? setEmailError(false)
            : setEmailError(true);
          break;
      }
    }
  }, [value]);

  // Boolean test
  useEffect(() => {
    if (isEmpty || minLengthError || emailError) {
      setInputValid(false);
    } else {
      setInputValid(true);
    }
  }, [isEmpty, minLengthError, emailError]);

  return {
    isEmpty,
    minLengthError,
    emailError,
    inputValid,
  };
};

// custom hook for Form-input
const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue); // handles changes inside the input
  const [isDirty, setIsDirty] = useState(false); // works when the user leaves the input field
  const valid = useValidation(value, validations); // error checking

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = (e) => {
    setIsDirty(true);
  };

  return {
    value,
    onChange,
    onBlur,
    isDirty,
    ...valid,
  };
};

const ParkingFeedback: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // const [name, setName] = useState<string>("");
  // const [email, setEmail] = useState<string>("");
  // const [emailError, setEmailError] = useState<string>("");
  // const [emailDirty, setEmailDirty] = useState<string>("");
  // const [message, setMessage] = useState<string>("");
  // const [messageError, setMessageError] = useState<string>("");
  // const [value, setValue] = useState('')
  const ref = useRef<any>();

  // Input-value
  const name = useInput("", { isEmpty: true, minLength: 2 });
  const email = useInput("", { isEmpty: true, isEmail: true });
  const message = useInput("", { isEmpty: true, minLength: 1 });

  // Button style - button is highlighted
  const [btnStyle, setBtnStyle] = useState({
    background: "#FFFFFF26",
    "&:hover": {
      backgroundColor: "#F7B500",
    },
  });
  const [imgStyle, setImgStyle] = useState({
    opacity: 1,
  });

  // When entering text, the button is highlighted
  let textarea = document.querySelector("textarea");
  // let form = document.querySelector("form");
  // let button = document.querySelector("button");
  // let inputs = document.querySelectorAll("input");

  useEffect(() => {
    if (textarea?.value !== "") {
      // textarea?.addEventListener("keydown", function (e) {
      setBtnStyle((previousState) => {
        return {
          ...previousState,
          background: "#F7B500",
        };
      });
      setImgStyle((previousState) => {
        return {
          ...previousState,
          opacity: 0.7,
        };
      });
      // });
    } else {
      setBtnStyle((previousState) => {
        return {
          ...previousState,
          background: "#FFFFFF26",
        };
      });
      setImgStyle((previousState) => {
        return {
          ...previousState,
          opacity: 1,
        };
      });
    }
  }, []);

  // Send-function
  function handleSubmit(event) {
   event.preventDefault();

    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    // .then((result) => {
    //     console.log(result.text);
    // }, (error) => {
    //     console.log(error.text);
    // });

    fetch("http://localhost:3002/send", {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          // alert("Message Sent.");
          toast.warn(translate("parking_feedback_alert"));
            // resetForm() - blank
          ref.current.value = "";
        } else if (response.status === "fail") {
          toast.warn("Message failed to send.");
          // alert("Message failed to send.");
        }
      });
    // console.log(
    //   "Name: " + name + ", Email: " + email + ", Message: " + message
    // );

  }

  // Alert
  function notify(e: any) {
    e.preventDefault();
    toast.warn(translate("parking_feedback_alert"));
  }

  return (
    <section className="parking_feedback">
      <div className="cont cont_md">
        <div className="">
          <h6>{translate("parking_feedback_sub")}</h6>
          <h2>{translate("parking_feedback_title")}</h2>
          <p> {translate("parking_feedback_subtitle")}</p>
        </div>
      </div>

      <div className="parking_form">
        <form ref={ref} id="contact-form" onSubmit={handleSubmit} method="POST">
          <div className="form-column">
            <div className="form-group name">
              <label className="form-label name" htmlFor="name">
                {translate("parking_feedback_name")}
              </label>
              {name.isDirty && name.isEmpty && (
                <div className="input_error">
                  {translate("parking_feedback_error_empty")}
                </div>
              )}
              {name.isDirty && name.minLengthError && (
                <div className="input_error">
                  {translate("parking_feedback_error_length")}
                </div>
              )}
              <input
                ref={ref}
                title="Name"
                type="text"
                className="form-control name"
                value={name.value}
                // onChange={(e) => setName(e.target.value)}
                onChange={(e) => name.onChange(e)}
                onBlur={(e) => name.onBlur(e)}
              />
            </div>
            <div className="form-group email">
              <label className="form-label email" htmlFor="exampleInputEmail1">
                {translate("parking_feedback_email")}
              </label>
              {email.isDirty && email.isEmpty && (
                <div className="input_error">
                  {translate("parking_feedback_error_empty")}
                </div>
              )}
              {email.isDirty && email.emailError && (
                <div className="input_error">
                  {translate("parking_feedback_email_error")}
                </div>
              )}
              <input
                title="E-mail"
                type="email"
                className="form-control email"
                aria-describedby="emailHelp"
                value={email.value}
                // onChange={(e) => setEmail(e.target.value)}
                onChange={(e) => email.onChange(e)}
                onBlur={(e) => email.onBlur(e)}
              />
            </div>
          </div>
          <div className="form-column">
            <div className="form-group message">
              <label className="form-label message" htmlFor="message">
                {translate("parking_feedback_message")}
              </label>
              {message.isDirty && message.isEmpty && (
                <div className="input_error">
                  {translate("parking_feedback_error_empty")}
                </div>
              )}
              {message.isDirty && message.minLengthError && (
                <div className="input_error">
                  {translate("parking_feedback_error_length")}
                </div>
              )}
              <textarea
                title="Message"
                className="form-control message"
                rows={7}
                value={message.value}
                // onChange={(e) => setMessage(e.target.value)}
                onChange={(e) => message.onChange(e)}
                onBlur={(e) => message.onBlur(e)}
              />
            </div>
            <button
              type="submit"
              className="form-btn"
              style={btnStyle}
              onClick={notify}
              disabled={
                !name.inputValid || !email.inputValid || !message.inputValid
              }
            >
              <img
                src={btn}
                alt="submit"
                className="form-btn_img"
                style={imgStyle}
                draggable="false"
              />
            </button>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </div>
        </form>
      </div>
    </section>
  );
};

export default ParkingFeedback;
