import axios from 'axios';
import { action, observable } from 'mobx';
import { signInWithCustomToken } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore';
import { Auth, Firestore } from '../firebase';

class StoreAuth {
    public auth: any;
    public app: string;

    constructor() {
        this.app = 'BeepPartner';
        this.auth = Auth
    }

    @observable public authId: string = '';

    @action.bound
    MutationConfirm(action: string) {
        this.authId = action;
    }

    async authSmsGet(phone: string): Promise<{ authId: string } | undefined> {
        try {
            const data = {
                data: {
                    phone
                }
            };

            const config = {
                method: 'post',
                url: 'https://europe-west1-beep-saas-dev.cloudfunctions.net/MainUsers-AuthSmsGet',
                headers: {
                    'beep-wl-app-bundle-id': 'com.beep.partnerdev',
                    'beep-wl-app-version': '3.0.3',
                    'beep-wl-app-country': 'UA',
                    'beep-wl-app-language': 'en',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios(config);
            this.MutationConfirm(response.data.result.authId);

            return { authId: this.authId };
        } catch (error) {
            console.log('StoreAuth => authSmsGet => error:', error);
        }
    }

    async authSms(code: string): Promise<string | undefined> {
        try {
            const data = {
                data: {
                    code: code,
                    authId: this.authId
                }
            };

            const config = {
                method: 'post',
                url: 'https://europe-west1-beep-saas-dev.cloudfunctions.net/MainUsers-AuthSmsConfirm',
                headers: {
                    'beep-wl-app-bundle-id': 'com.beep.partnerdev',
                    'beep-wl-app-version': '3.0.3',
                    'beep-wl-app-country': 'UA',
                    'beep-wl-app-language': 'en',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios(config);

            const result = response.data.result;

            if (!result.code && !result.error) {
                const resToken = await signInWithCustomToken(this.auth, result);
                const newToken = await resToken.user.getIdToken();

                await this.checkAndCreatePartner(resToken.user.uid, newToken)

                return newToken;
            } else {
                return result;
            }

        } catch (error: any) {
            console.log('StoreAuth => authSms => error:', error.message);
        }
    }

    async checkAndCreatePartner(uid: string, token: string): Promise<void> {
        const docRef = doc(Firestore, 'APP', this.app, 'partner-members', uid);

        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            await this.PartnerMembersEdit(token);
        }
    }

    async PartnerMembersEdit(token: string): Promise<void> {
        try {
            const data = {
                data: {
                    firstName: 'User',
                }
            };

            const config = {
                method: 'post',
                url: 'https://europe-west1-beep-saas-dev.cloudfunctions.net/PartnerMembers-Edit',
                headers: {
                    'beep-wl-app-bundle-id': 'com.beep.partnerdev',
                    'beep-wl-app-version': '3.0.3',
                    'beep-wl-app-country': 'UA',
                    'beep-wl-app-language': 'en',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            await axios(config)

        } catch (error: any) {
            console.log('StoreAuth => PartnerMembersEdit => error:', error);
        }
    }
}

export default new StoreAuth();
