import React, { FC, useContext } from "react";

interface ItemImg {
  header: string;
  text: string;
}

const ListItem: FC<ItemImg> = ({ header, text }) => {

  return (
    <div className="list-item">
      <h5>{header}</h5>
      <p className="text">{text}</p>
    </div>
  );
};

export default ListItem;
