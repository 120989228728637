import { SvgIcon } from '@mui/material'
import { styled } from "@mui/system";

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    width: 20,
    height: 40,
    position: 'absolute',
    top: '50%',
    "&.MuiSelect-iconOpen": {
        top: 0,
        right: 16,
    },
}));

function ChevronDown(props) {

    return (
        <StyledSvgIcon
            {...props}
        >
            <svg
                width={20}
                height={40}
                viewBox="0 0 20 40"
                fill="none"
            >
                <path
                    d="M6 4.726L1.619.22A.996.996 0 10.373 1.775L5.377 6.78a.996.996 0 001.246 0l5.004-5.006A.997.997 0 0010.38.219L6 4.726z"
                    fill="#8E8E93"
                />
            </svg>
        </StyledSvgIcon>
    )
}

export default ChevronDown
