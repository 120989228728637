import React, { FC, useContext } from "react";
import { LangContext } from "../../context/lang";
import enmain from "../../img/en/parking/hero/en_parking_hero.png";
import ukmain from "../../img/uk/parking/hero/uk_parking_hero.png";
import trmain from "../../img/tr/parking/hero/tr_parking_hero.png";
import bg from "../../img/parking/hero/parking_hero_bg2.png";
import partner from "../../img/parking/hero/bPartner_en.png";
import find from "../../img/insurance/hero/find.png";

import "../../scss/_parkingHero.scss";

// The Image on the right in the selected language
const images = {
  EN: {
    img: enmain,
  },
  UK: {
    img: ukmain,
  },
  TR: {
    img: trmain,
  },
};

type Lang = "EN" | "UK" | "TR";

const ParkingHero: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <>
      <section className="parking_hero">
        <div className="cont_md cont">
          <div className="content-wrap">
            <h1 className="heroZoomIn yellow">
              {translate("parking_hero_firsttitle_yellow")}
            </h1>
            <h1 className="heroZoomIn">
              {translate("parking_hero_firsttitle_white")}
            </h1>
            <h1 className="heroZoomIn yellow">
              {translate("parking_hero_secondtitle_yellow")}
            </h1>
            <h1 className="heroZoomIn">
              {translate("parking_hero_secondtitle_white")}
            </h1>
            <h1 className="heroZoomIn yellow">
              {translate("parking_hero_thirdtitle_yellow")}
            </h1>
            <h1 className="heroZoomIn">
              {translate("parking_hero_thirdtitle_white")}
            </h1>
            <div className="parking_href-section">
              <p>
                <span>
                  {translate("parking_hero_subtitle_first")}&nbsp;
                  <img
                    className="parking_logo-partner"
                    src={partner}
                    alt="logo-partner"
                    draggable="false"
                  />
                </span>
                <span>&nbsp;{translate("parking_hero_subtitle_second")}</span>
              </p>
            </div>
            <a
              href="https://app.beep.club/website"
              target="_blank"
            >
              <button className="btn">{translate("parking_hero_btn")}</button>
            </a>
          </div>
        </div>
        <p className="parking_bottom_subtitle">
          <img src={find} alt="Find" draggable="false" />
          {translate("hero_text1")}
          <a href="https://apps.apple.com/app/id1505288985" target="_blank">
            {" "}
            AppStore{" "}
          </a>{" "}
          {translate("hero_text2")}
          <a
            href="https://play.google.com/store/apps/details?id=com.bac.client"
            target="_blank"
          >
            {" "}
            GooglePlay
          </a>
          {translate("hero_text3")}
        </p>

        <div className="parking_back">
          <div className="parking_img-wrap parking_mobile">
            <img
              src={images[language as Lang].img}
              alt="ParkingHero"
              draggable="false"
            />
          </div>
          <div className="parking_img-wrap parking_map">
            <img src={bg} alt="ParkingHeroBg1" draggable="false" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ParkingHero;
