import React, { FC, useContext, useEffect } from "react";
import LangState, { LangContext } from "../../context/lang";

import { observer } from "mobx-react";
import StoreCounters from "../../store/counters.store";

import "../../scss/_insuranceJoinNow.scss";
import { Languages } from "../../store/interfaces/enums";
import JoinCounters from "../JoinCounters";

const InsuranceJoinNow: FC = observer(() => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Hook for work with store and DataBase of firebase with mobx
  useEffect(() => {
    StoreCounters.onCounters();

    return function cleanup() {
      StoreCounters.offCounters();
    };
  }, []);

  return (
    <section className="insurance_joinnow">
      <JoinCounters
        title_main={translate("insurance_joinnow_header2")}
        content_main={translate("insurance_joinnow_content")}
        conter_top={translate("insurance_joinnow_conter_top")}
        counters_title={
          StoreCounters.membersCount !== null ? (
            <p className="item-counters_title">{StoreCounters.membersCount}</p>
          ) : null
        }
        conter_top2={translate("insurance_joinnow_conter_top2")}
        conter_bottom0={translate("insurance_joinnow_conter_bottom0")}
        conter_bottom={translate("insurance_joinnow_conter_bottom")}
        topblock_top={translate("insurance_joinnow_trusted_top")}
        topblock_bottom={translate("insurance_joinnow_trusted_bottom")}
        bottomblock_top={translate("insurance_joinnow_secure_top")}
        bottomblock_bottom={translate("insurance_joinnow_secure_bottom")}
        logo_boolean={true}
      />
    </section>
  );
});

export default InsuranceJoinNow;
