import React, { FC, useContext, useEffect } from "react";
import LangState, { LangContext } from "../../context/lang";

import "../../scss/_homeJoinNow.scss";
import { Languages } from "../../store/interfaces/enums";
import ButtonsDownload from "../ButtonsDownload";

import logo from "../../img/logo.png";
import AppStoreWhite from "../../img/AppStoreWhite.png";
import GooglePlayWhite from "../../img/GooglePlayWhite.png";

const HomeJoin: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <section className="home_joinnow">
      <div className="cont cont_sm">
        <div className="grid">
          <div className="item">
            <div className="item-left">
              <h2>{translate("home_join_title_first")}</h2>
              <p>{translate("home_join_subtitle_first")}</p>
              <ButtonsDownload />
            </div>
          </div>
          <div className="item">
            <div className="item-right">
              <div className="bg">
                <div className="logo-section">
                  <h2>{translate("home_join_title_second_one")}</h2>

                  {language &&
                    (language === "TR" ? (
                      <>
                        <div className="home_join_tr">
                          <div className="home_join_tr-item1">
                            <img
                              className="small_tr-img"
                              src={logo}
                              alt="logo"
                            />
                            <h2 className="small_tr">
                              {" "}
                              {translate("home_join_title_second_two")}
                            </h2>
                          </div>{" "}
                          <div className="home_join_tr-item2">
                            <h2 className="small_tr">
                              {translate("home_join_title_second_three")}
                            </h2>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={logo} alt="logo" draggable="false" />
                        <h2> {translate("home_join_title_second_two")}</h2>
                      </>
                    ))}
                </div>
                <p> {translate("home_join_subtitle_second")}</p>
                <div className="btn-download">
                  <a
                    href="https://apps.apple.com/app/id1551798314"
                    target="_blank"
                    // rel="noreferrer"
                    // rel="noopener"
                  >
                    <button className="btn appstore_white">
                      <img
                        src={AppStoreWhite}
                        alt="AppStore"
                        draggable="false"
                      />
                    </button>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.beep.partner"
                    target="_blank"
                  >
                    <button className="btn googleplay_white">
                      <img
                        src={GooglePlayWhite}
                        alt="GooglePlay"
                        draggable="false"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeJoin;
