import { Select, MenuItem, FormControl } from '@mui/material';
import { ISelectValue } from '../data';
import ChevronDown from './ChevronDown';

interface SimpleSelectProps {
  id: string;
  label: string;
  options: ISelectValue[];
  value: string[];
  errorMessage: string;
  onChange: (id: string, value: string[]) => void;
  error: boolean;
  formSubmitted: boolean;
  translate: (key: string) => string
}

const SimpleSelect = (props: SimpleSelectProps) => {
  const { id, label, options = [], value = [], onChange, error, formSubmitted, errorMessage, translate } = props;

  const allItems = translate('сar_srvc_partner_dropdown_all')

  const handleChange = (event: string[]) => {
    let newValues = event;

    if (event.includes(allItems) && event.length === 1) {
      newValues = options.map(o => o.id);
    } else if (event.includes(allItems) && event.length > 1) {
      newValues = [];
    }

    onChange(id, newValues);
  }

  const renderValue = (option: ISelectValue) => {
    if (id === 'vehicleBrand') {
      return option.value
    } else if (translate(option.value)) {
      return translate(option.value)
    } else {
      return option.id
    }
  }

  return (
    <div key={id} className="form-group">
      <label className="form-label">{label}</label>
      <FormControl error={formSubmitted && error}>
        <Select
          fullWidth
          multiple
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          error={formSubmitted && error}
          onChange={(e) => handleChange(e.target.value as string[])}
          IconComponent={ChevronDown}
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={selected => {
            return selected.map(val => {
              const option = options.find(o => o.id === val || o.value === val);
              if (option) {
                return id === 'vehicleBrand' ? option.value : translate(option.value);
              }
              return val;
            }).join(', ');
          }}
          MenuProps={{
            sx: {
              fontSize: '16px !important',
              '& .MuiPaper-root': {
                borderRadius: '20px',
                maxHeight: '420px',
                maxWidth: '200px !important',
                '& .MuiMenuItem-root': {
                  color: '#fff',
                  zIndex: 100,
                  pl: '24px !important',
                },
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderColor: '#fff',
                  height: '40px',
                  borderRadius: '15px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#424A54',
                  maxHeight: '180px',
                  paddingTop: '10px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  borderRadius: '15px',
                },
              },
            }
          }}
          sx={{
            bgcolor: '#ffffff26',
            borderRadius: '15px',
            fontSize: '17px !important',
            pl: '4px !important',
            '& .MuiSelect-icon': {
              color: '#fff',
            },
            '&.Mui-focused': {
              color: '#fff',
              backgroundColor: '#343538 !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              transition: 'all 0.3s ease',
              borderColor: '#F7B500 !important',
              borderWidth: '2px !important',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#F7B500 !important',
            },
          }}
        >
          <MenuItem
            value={allItems}
          >
            {allItems}
          </MenuItem>

          {!!options.length && options?.map(option => {
            const isChecked = value.includes("All") || value.includes(option.id);

            return (
              <MenuItem
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '40px'
                }}
                key={option.id}
                value={option.id}
              >
                {renderValue(option)}

                {isChecked &&
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.81713 5.41705L10.107 0.313365C10.54 -0.104455 11.2421 -0.104455 11.6752 0.313365C12.1083 0.731184 12.1083 1.4086 11.6752 1.82642L5.60125 7.68664C5.16819 8.10445 4.46606 8.10445 4.03301 7.68664L0.324794 4.10892C-0.108265 3.6911 -0.108265 3.01368 0.324794 2.59586C0.757853 2.17804 1.45998 2.17804 1.89304 2.59586L4.81713 5.41705Z" fill="#F7B500" />
                  </svg>
                }
              </MenuItem>
            )
          })}
        </Select>
        {formSubmitted && error && <div className="input_error">{errorMessage}</div>}

      </FormControl>
    </div>
  );
}
export default SimpleSelect;