import React, { FC, useContext, useEffect } from "react";

import AppStore from "../img/AppStore.png";
import GooglePlay from "../img/GooglePlay.png";

const ButtonsDownload: FC = () => {
  return (
    <div className="btn-download">
      <a
        href="https://apps.apple.com/app/id1505288985"
        target="_blank"
      >
        <button className="btn appstore">
          <img src={AppStore} alt="AppStore" draggable="false"/>
        </button>
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.bac.client" target="_blank">
        <button className="btn googleplay">
          <img src={GooglePlay} alt="GooglePlay" draggable="false"/>
        </button>
      </a>
    </div>
  );
};

export default ButtonsDownload;
