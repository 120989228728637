import {action, observable, computed, makeObservable} from 'mobx';
import {ref, onValue, off} from "firebase/database";
import sortBy from 'lodash/sortBy';

import {RealtimeDB} from '../firebase';
import {IFeedback} from "./interfaces";

class StoreCounters {
    constructor() {
        makeObservable(this);
    }

    private debug: boolean = false;

    private log(type: 'log' | 'warn' | 'error', message?: any,  ...optionalParams: any[]) {
        if (this.debug) {
            console[type](message, ...optionalParams);
        }
    }

    @observable public membersCount: number = 0;

    @action MutationMembersCount(data: number) {
        this.log('log', 'StoreCounters.MutationMembersCount data:', data);
        this.membersCount = data;
    }

    onCounters() {
        this.log('log', 'StoreCounters.onCounters');

        const countersRef = ref(RealtimeDB, '/counters');
        onValue(countersRef, (snapshot => {
            const counters = snapshot.val() as {members: number};
            this.MutationMembersCount(counters.members || 0);
        }), );
    }

    offCounters() {
        this.log('log', 'StoreCounters.offCounters');
        const countersRef = ref(RealtimeDB, '/counters');
        off(countersRef);
    }
}

export default new StoreCounters();
