import axios from 'axios';
import { ref, get } from "firebase/database";
import { getDownloadURL, ref as refStrg, uploadBytes } from "firebase/storage";
import { action, observable } from 'mobx';
import { RealtimeDB2, Storage } from '../firebase';
import kebabCase from 'lodash/kebabCase';
import { sortBy } from 'lodash';
import { IImage } from '../components/auto-service/components/ImageUploadButton';
import { IServicePoint } from '../components/auto-service/interfaces';
import { IMediaFile } from './interfaces';
import authStore from './auth.store';

interface ISelectValue {
    id: string;
    value: string;
}

class StoreAutoServicePartner {
    @observable public carsMake: Array<ISelectValue> = [];
    @observable public loading: boolean = false;

    @action
    MutationLoading(loading: boolean): void {
        this.loading = loading;
    }

    @action
    MutationCarsMake(data: Array<ISelectValue>) {
        this.carsMake = data;
    }

    async getMakeCars(): Promise<ISelectValue[] | undefined> {
        this.MutationLoading(true);

        const carMakeDB = ref(RealtimeDB2, `car-make`);
        const cacheDataSnapshot: any = await get(carMakeDB)
        const cacheData = cacheDataSnapshot.val();

        let result: Array<ISelectValue> = [];

        if (cacheData) {
            result = cacheData.map((doc: any) => {
                return {
                    value: doc.name,
                    id: kebabCase(doc.name),
                };
            });


            result = sortBy(result, ['value']);
        }

        this.MutationCarsMake(result);
        this.MutationLoading(false);
        return result
    }

    async uploadImage(files: IImage[], id: string): Promise<IMediaFile[] | undefined> {
        try {
            const uploadPromises = files.map(async (file: IImage) => {
                const size = (file.file.size / 1024).toFixed(2);
                const name = file.file.name;
                const storageRef = refStrg(Storage, `APP/BeepPartner/service-points/${id}/photos/${file.id}`);

                await uploadBytes(storageRef, file.file);
                const url = await getDownloadURL(storageRef);

                return {
                    url,
                    meta: {
                        name,
                        isValid: true,
                        size,
                        isMain: false,
                    }
                };
            });

            return await Promise.all(uploadPromises);
        } catch (error: any) {
            console.log('StoreAutoServicePartner => uploadImage => error:', error);
        }
    }

    async partnerServicePoints(patload: IServicePoint, token?: string): Promise<IServicePoint | undefined> {
        try {

            const data = {
                data: {
                    ...patload,
                }
            };

            const config = {
                method: 'post',
                url: 'https://europe-west1-beep-saas-dev.cloudfunctions.net/PartnerServicePoints-Edit',
                headers: {
                    'beep-wl-app-bundle-id': 'com.beep.partnerdev',
                    'beep-wl-app-version': '3.0.3',
                    'beep-wl-app-country': 'UA',
                    'beep-wl-app-language': 'en',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const response = await axios(config)

            return response.data.result;
        } catch (error: any) {
            console.log('StoreAutoServicePartner => partnerServicePoints => error:', error);
        }
    }

    async processAuthAndUpload(code: string, images: IImage[], servicePoint: IServicePoint): Promise<IServicePoint | undefined | any> {
        try {
            const token = await authStore.authSms(code);

            if (typeof token === 'string') {

                const response = await this.partnerServicePoints({}, token);

                const id = response?.id;

                if (!id) {
                    throw new Error('Ошибка получения id');
                }

                const mediaFiles = await this.uploadImage(images, id);

                const fullData: IServicePoint = {
                    ...response,
                    ...servicePoint,
                    photos: mediaFiles,
                    origin: 'WEB'
                };

                return await this.partnerServicePoints(fullData, token);

            } else {
                const errorToken = token as any;
                if (errorToken?.error?.code && errorToken.error.message) {
                    return {
                        error: errorToken.error.message,
                    };
                }
            }

        } catch (error) {
            console.log('StoreAutoServicePartner => processAuthAndUpload => error:', error);
        }
    }


}

export default new StoreAutoServicePartner();