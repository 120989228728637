import React, { FC, useContext } from "react";
import { LangContext } from "../../context/lang";

import "../../scss/_homeTeam.scss";
// import ButtonsDownload from "../ButtonsDownload";
//
// import Img1 from "../../img/home/partner/registr.png";
// import Img2 from "../../img/home/partner/promote.png";
// import Img3 from "../../img/home/partner/create.png";
// import Img4 from "../../img/home/partner/payments.png";
// import Img5 from "../../img/bookpay/services/serv5.png";
// import iPhone from "../../img/home/partner/iPhone_partner.png";
import SliderTeam from "../SliderTeam";

import Img1first from "../../img/home/team/slider1/1.P.Lovas.png";
import Img2first from "../../img/home/team/slider1/2.J.Richards.png";
import Img3first from "../../img/home/team/slider1/3.Y.Glotov.png";
import Img4first from "../../img/home/team/slider1/4.A.Arshinskyi.png";
import Img5first from "../../img/home/team/slider1/5.A.Lorenz.png";
import Img6first from "../../img/home/team/slider1/6.D.Okumus.png";
import Img7first from "../../img/home/team/slider1/7.S.Suer.png";
import Img8first from "../../img/home/team/slider1/8.M.Kharchuk.png";
import Img9first from "../../img/home/team/slider1/9.V.Savenok.png";
import Img10first from "../../img/home/team/slider1/10.M.Maslo.png";
import Img11first from "../../img/home/team/slider1/11.A.Balakirska.png";
import Img12first from "../../img/home/team/slider1/12.Y.Solomakha.png";

import Img1second from "../../img/home/team/slider2/1.O.Primorozenko.png";
import Img2second from "../../img/home/team/slider2/2.A.Tupchienko.png";
import Img3second from "../../img/home/team/slider2/3.A.Kostenko.png";
import Img4second from "../../img/home/team/slider2/4.A.Kostenko.png";
import Img5second from "../../img/home/team/slider2/5.A.Berezhnyy.png";
import Img6second from "../../img/home/team/slider2/6.A.Voit.png";
import Img7second from "../../img/home/team/slider2/7.A.Kovalyov.png";
import Img8second from "../../img/home/team/slider2/8.M.Vorontsov.png";
import Img9second from "../../img/home/team/slider2/9.V.Lysenko.png";
import Img10second from "../../img/home/team/slider2/10.O.Dramarec.png";
import Img11second from "../../img/home/team/slider2/11.S.Falandysh.png";
import Img12second from "../../img/home/team/slider2/12.Y.Kontirova.png";
import Img13second from "../../img/home/team/slider2/13.I.Samoluk.png";
// import { ISlidersTeamProps } from "../interfaces/ISlidersTeamProps";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";

// Images for Grid. src
const images1 = {
  img1: Img1first,
  img2: Img2first,
  img3: Img3first,
  img4: Img4first,
  img5: Img5first,
  img6: Img6first,
  img7: Img7first,
  img8: Img8first,
  img9: Img9first,
  img10: Img10first,
  img11: Img11first,
  img12: Img12first,
};
const images2 = {
  img1: Img1second,
  img2: Img2second,
  img3: Img3second,
  img4: Img4second,
  img5: Img5second,
  img6: Img6second,
  img7: Img7second,
  img8: Img8second,
  img9: Img9second,
  img10: Img10second,
  img11: Img11second,
  img12: Img12second,
  img13: Img13second,
};
// type Lang = "EN" | "UK" | "TR";

const HomeTeam: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Employee data array
  const sliders1 = [
    {
      name: "home_team_slider_first_name1",
      position: "home_team_slider_first_position1",
      linkedin: "https://www.linkedin.com/in/peter-lovas-5996a3/",
      img: {
        src: images1.img1,
        alt: "Peter Lovas",
      },
    },
    {
      name: "home_team_slider_first_name2",
      position: "home_team_slider_first_position2",
      linkedin: "https://www.linkedin.com/in/john-richards-4293a8/",
      img: {
        src: images1.img2,
        alt: "John Richards",
      },
    },
    {
      name: "home_team_slider_first_name3",
      position: "home_team_slider_first_position3",
      linkedin: "https://www.linkedin.com/in/evgeniy-glotov-29844a38/",
      img: {
        src: images1.img3,
        alt: "Yevhen Glotov",
      },
    },
    {
      name: "home_team_slider_first_name4",
      position: "home_team_slider_first_position4",
      linkedin: "https://www.linkedin.com/in/oarsheo/",
      img: {
        src: images1.img4,
        alt: "Aleksandr Arshinskyi",
      },
    },
    {
      name: "home_team_slider_first_name5",
      position: "home_team_slider_first_position5",
      linkedin: "https://www.linkedin.com/in/alexander-s-lorenz-2366a31a9/",
      img: {
        src: images1.img5,
        alt: "Alexander Lorenz",
      },
    },
    {
      name: "home_team_slider_first_name6",
      position: "home_team_slider_first_position6",
      linkedin: "https://www.linkedin.com/in/derya-okumus-aa432a13/",
      img: {
        src: images1.img6,
        alt: "Derya Okumus",
      },
    },
    // {
    //   name: "home_team_slider_first_name7",
    //   position: "home_team_slider_first_position7",
    //   linkedin: "https://www.linkedin.com/in/serkan-s-6247a51b/",
    //   img: {
    //     src: images1.img7,
    //     alt: "Serkan Suer",
    //   },
    // },
    {
      name: "home_team_slider_first_name8",
      position: "home_team_slider_first_position8",
      linkedin: "https://www.linkedin.com/in/maryna-kharchuk-49a85a66/",
      img: {
        src: images1.img8,
        alt: "Marina Kharchuk",
      },
    },
    {
      name: "home_team_slider_first_name9",
      position: "home_team_slider_first_position9",
      linkedin:
        "https://www.linkedin.com/in/%D0%B2%D0%B0%D0%BB%D0%B5%D0%BD%D1%82%D0%B8%D0%BD%D0%B0-%D1%81%D0%B0%D0%B2%D0%B5%D0%BD%D0%BE%D0%BA-a9b295210/",
      img: {
        src: images1.img9,
        alt: "Valentina Savenok",
      },
    },
    {
      name: "home_team_slider_first_name10",
      position: "home_team_slider_first_position10",
      linkedin: "https://www.linkedin.com/in/marina-maslo-b6737140",
      img: {
        src: images1.img10,
        alt: "Marina Maslo",
      },
    },
    {
      name: "home_team_slider_first_name11",
      position: "home_team_slider_first_position11",
      linkedin: "https://www.linkedin.com/in/alisa-balakirska-4726b117a/",
      img: {
        src: images1.img11,
        alt: "Alisa Balakirska",
      },
    },
    {
      name: "home_team_slider_first_name12",
      position: "home_team_slider_first_position12",
      linkedin: "https://www.linkedin.com/in/yurii-solomakha-334b74100/",
      img: {
        src: images1.img12,
        alt: "Yurii Solomakha",
      },
    },

    {
      name: "home_team_slider_second_name1",
      position: "home_team_slider_second_position1",
      linkedin: "https://www.linkedin.com/in/olga-primorozenko-279ab8ba/",
      img: {
        src: images2.img1,
        alt: "Olga Primorozenko",
      },
    },
    {
      name: "home_team_slider_second_name2",
      position: "home_team_slider_second_position2",
      linkedin: "https://www.linkedin.com/in/andriit",
      img: {
        src: images2.img2,
        alt: "Andrii Tupchiienko",
      },
    },
    {
      name: "home_team_slider_second_name3",
      position: "home_team_slider_second_position3",
      linkedin: "https://www.linkedin.com/in/anton-kostenko-675a10145/",
      img: {
        src: images2.img3,
        alt: "Anton Kostenko",
      },
    },
    {
      name: "home_team_slider_second_name4",
      position: "home_team_slider_second_position4",
      linkedin: "https://www.linkedin.com/in/annalukash",
      img: {
        src: images2.img4,
        alt: "Anna Kostenko",
      },
    },
    {
      name: "home_team_slider_second_name5",
      position: "home_team_slider_second_position5",
      linkedin: "",
      img: {
        src: images2.img5,
        alt: "Andriy Berezhnyy",
      },
    },
    {
      name: "home_team_slider_second_name6",
      position: "home_team_slider_second_position6",
      linkedin: "https://www.linkedin.com/in/anton-voit-69b457230/",
      img: {
        src: images2.img6,
        alt: "Anton Voit",
      },
    },
    // {
    //   name: "home_team_slider_second_name7",
    //   position: "home_team_slider_second_position7",
    //   img: {
    //     src: images2.img7,
    //     alt: "Aleksandr Kovalyov",
    //   },
    // },
    // {
    //   name: "home_team_slider_second_name8",
    //   position: "home_team_slider_second_position8",
    //   linkedin:
    //     "https://www.linkedin.com/in/mykhailo-mike-vorontsov-57b7ba198/?locale=en_US",
    //   img: {
    //     src: images2.img8,
    //     alt: "Mike Vorontsov",
    //   },
    // },
    // {
    //   name: "home_team_slider_second_name9",
    //   position: "home_team_slider_second_position9",
    //   img: {
    //     src: images2.img9,
    //     alt: "Vladimir Lysenko",
    //   },
    // },
    {
      name: "home_team_slider_second_name10",
      position: "home_team_slider_second_position10",
      linkedin: "https://www.linkedin.com/in/dramarec/",
      img: {
        src: images2.img10,
        alt: "Oleg Dramarec",
      },
    },
    {
      name: "home_team_slider_second_name11",
      position: "home_team_slider_second_position11",
      linkedin: "https://www.linkedin.com/in/sergey-falandysh-a83536107",
      img: {
        src: images2.img11,
        alt: "Sergey Falandysh",
      },
    },
    {
      name: "home_team_slider_second_name12",
      position: "home_team_slider_second_position12",
      linkedin: "https://www.linkedin.com/in/yuliia-kontirova-315409191/",
      img: {
        src: images2.img12,
        alt: "Yuliia Kontirova",
      },
    },
    {
      name: "home_team_slider_second_name13",
      position: "home_team_slider_second_position12",
      linkedin: "https://linkedin.com/in/iryna-samoluk-935a19204",
      img: {
        src: images2.img13,
        alt: "Iryna Samoluk",
      },
    },
  ];

  let slidesToShow1 = 7;
  // let slidesToShow2 = 8;
  let slidesToShow11 = 5;
  // let slidesToShow21 = 6;
  let slidesToShow12 = 4;
  // let slidesToShow22 = 5;
  let slidesToShow13 = 3;
  // let slidesToShow23 = 4;
  let slidesToShow14 = 3;
  // let slidesToShow24 = 3;

  let Direction1: boolean = false;
  // let Direction2: boolean = true;

  // let speed1 = 5000;
  // let speed2 = 10000;
  let speed3 = 20000;
  // let speed4 = 30000;

  return (
    <>
      <section className="home_team">
        <svg
          width="1919"
          height="438"
          viewBox="0 0 1919 438"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-d"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M0 244.926C194.373 390.49 385.656 278.265 602.5 387.572C1092.5 634.573 1186.35 -150.583 1552 27.5719C1806.5 151.572 1729.5 214.072 1918 267.572"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>
        <svg
          width="1023"
          height="468"
          viewBox="0 0 1023 468"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-t"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M-572 244.926C-377.627 390.49 -186.344 278.265 30.5 387.572C520.5 634.573 614.351 -150.583 980 27.5719C1234.5 151.572 1157.5 214.072 1346 267.572"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <svg
          width="767"
          height="259"
          viewBox="0 0 767 259"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-m"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.5"
            d="M-336 133.866C-229.997 213.153 -125.679 152.025 -7.42076 211.564C259.806 346.104 310.988 -81.5667 510.398 15.4736C649.192 83.0158 607.2 117.059 767 146.201"
            stroke="#F7B500"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <div className="cont cont_sm">
          <h6>{translate("home_team_name")}</h6>
          <div className="home_team-container">
            <h2 id="feature">{translate("home_team_bigtitle")}</h2>
            <p className="home_team_subtitle">
              {translate("home_team_subtitle")}
            </p>
          </div>
        </div>
        <div className="slide-team1">
          <SliderTeam
            arr={sliders1}
            direction={Direction1}
            speed={speed3}
          />
        </div>
      </section>
    </>
  );
};

export default HomeTeam;
