import { FC } from "react";
import TemplatePage from "./TemplatePage";
import AutoServiceForm from '../components/auto-service/AutoServiceForm';
import Service from "../img/uk/auto-service/auto_service.png";
import "../scss/_autoServicePartner.scss";

interface ServicePageProps {
    translate: (key: string) => string;
}

const AutoServicePartnerPage: FC<ServicePageProps> = ({ translate }) => {
    return (
        <TemplatePage>
            <AutoServiceForm translate={translate} />
            <div className="auto_service_img hidden">
                <img
                    src={Service}
                    alt="ParkingHero"
                    draggable="false"
                />
            </div>
        </TemplatePage>
    );
};

export default AutoServicePartnerPage;
