// @flow
import React, { FC } from "react";
import Telegram from "../img/telegram.png";
import Instagram from "../img/instagram.png";
import Facebook from "../img/facebook.png";
import LinkedIn from "../img/linkedin.png";

const Social: FC = () => {
  return (
    <>
      <div className="socialmedia">
        <div className="socialmedia icons">
          <a href="https://t.me/BeepClub_bot" target="_blank">
            <img src={Telegram} className="telegram" alt="Telegram" draggable="false"/>
          </a>
        </div>
        <div className="socialmedia icons">
          <a
            href="https://www.instagram.com/accounts/login/?next=/beep.club/"
            target="_blank"
          >
            <img src={Instagram} alt="Instagram" draggable="false"/>
          </a>
        </div>
        <div className="socialmedia icons">
          <a href="https://www.facebook.com/beepautoclub" target="_blank">
            <img src={Facebook} alt="Facebook" draggable="false"/>
          </a>
        </div>
        <div className="socialmedia icons">
          <a href="https://www.linkedin.com/company/beep-club/" target="_blank">
            <img src={LinkedIn} alt="LinkedIn" draggable="false" />
          </a>
        </div>
      </div>
      <div className="mail">
        <a className="bottom-email" href="mailto:info@beep.club">
          info@beep.club
        </a>
      </div>
    </>
  );
};

export default Social;
