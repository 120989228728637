import { MuiOtpInput } from 'mui-one-time-password-input'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "../../../scss/_autoServicePartner.scss";
import styled from '@emotion/styled';

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 16px;
  max-width: 370px;
`

interface IPayload {
  open: boolean;
  handleClose: () => void;
  phoneNumber: string;
  handleSubmit: () => void;
  otp: string;
  setOtp: (otp: string) => void;
  loading?: boolean;
  translate: (key: string) => string;
}

const MuiOtpInputModal = (payload: IPayload) => {
  const { open, handleClose, phoneNumber, handleSubmit, otp, setOtp, loading, translate } = payload;

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const closeModal = () => {
    handleClose()
    setOtp('')
  }

  return (
    <div className="otp-container">
      <Dialog open={open} onClose={closeModal}
        components={{
          Backdrop: Backdrop,
        }}
        componentsProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(2px)",
            }
          }
        }}
        sx={{
          '& .MuiDialog-paper': {
            p: 4,
            backgroundColor: '#191D22',
            color: '#F7B500',
            borderRadius: '20px',
          }
        }}>
        <DialogTitle
          id='customized-dialog-title'
          sx={{
            p: 0,
            mb: 4,
          }}>
          <Typography variant='h1' component='span' sx={{ fontSize: 24, fontWeight: 800 }} >
            {translate('сar_srvc_partner_sms_title')}
          </Typography>
          <Typography variant='body1' sx={{ fontSize: 18, color: 'grey', mt: 3 }}>
            {translate('сar_srvc_partner_sms_text')}
          </Typography>
          <Typography variant='body1' sx={{ fontSize: 18 }}>
            +{phoneNumber}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={closeModal}
            sx={{
              top: 5, right: 5, position: 'absolute',
              color: 'grey.100',
              backgroundColor: '#131518',
            }}
          >
            <CloseIcon sx={{ width: 16, height: 16, }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            px: 1,
            py: 1
          }}
        >
          <MuiOtpInputStyled
            value={otp}
            length={6}
            onChange={handleChange}
            autoFocus={true}
            TextFieldsProps={{
              sx: {
                borderColor: '#2A3038 !important',
                '&.Mui-focused': {
                  backgroundColor: '#444A52',
                  borderColor: '#444A52 !important',
                },
                '& .MuiInputBase-input': {
                  p: 0,
                  width: 50,
                  height: 50,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2A3038 !important',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#444A52 !important',
                  backgroundColor: '#444A52 !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2A3038 !important',
                },

              }
            }}
          />

        </DialogContent>
        <DialogTitle sx={{ p: 0, py: 3 }}>
          <Typography variant='body1' sx={{ fontSize: 13, color: 'grey' }}>
            {translate('сar_srvc_partner_sms_time')}
          </Typography>
        </DialogTitle>

        <DialogActions sx={{ px: 3, p: 0 }}>
          <Button
            type='submit'
            onClick={handleSubmit}
            fullWidth
            disabled={!otp || otp.length < 6 || loading}
            startIcon={loading && <CircularProgress size={26} color='inherit' />}
            sx={{
              backgroundColor: otp && otp.length === 6 ? '#F7B500' : '#F7B50030',
              color: '#191D22',
              fontSize: 18,
              textTransform: 'none',
              borderRadius: 4,
              p: 4,
              height: 44,
              '&:hover': {
                backgroundColor: '#f7b50090'
              },
            }}
          >
            {!loading ? translate('сar_srvc_partner_confirm_btn') : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MuiOtpInputModal;
