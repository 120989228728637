import React, { FC, useContext, useState, useEffect, useRef } from "react";
import LangState, { LangContext } from "../context/lang";
import Slider from "react-slick";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import "../scss/_insuranceSlider.scss"; // _general.scss
import { IImagesComponents } from "../store/interfaces/images";
import { ISlidersProps } from "./interfaces/ISlidersProps";

interface IProps {
  arr: Array<ISlidersProps>;
  heading: string;
  title: string;
}

type Lang = "EN" | "UK" | "TR";

// const Sliders: FC<ISliders[]> = ({...sliders}: Array<ISliders> ) => {
const Sliders: FC<IProps> = ({ arr, heading, title }: IProps) => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);
  // Custom range
  const [slideIndex, setSlideIndex] = useState<number | any>(0);

  // Main slider display settings
  let settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 600,
    vertical: true,
    verticalSwiping: true,
    cssEase: "linear",
    lazyload: true,
    autoplay: true,
    pauseOnFocus: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    touchMove: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    adaptiveHeight: true,
    // afterChange: (current, next) => {setSlideIndex({ slideIndex: next })},
    beforeChange: (current, next) => {
      setSlideIndex((slideIndex) => next);
    },
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Scroll
  const slider = useRef<any>(null);

  // const scroll = (e) => {
  //   // if (slider === null) return 0;
  //   // e.wheelDelta > 0 ? slider.current.slickNext() : slider.current.slickPrev();
  //   // const element = window.document.getElementById('slider');
  //   // const position = element?.getBoundingClientRect();
  //   //
  //   // if(position) {
  //   //   if (position.top >= 0 && position.bottom <= window.innerHeight) {
  //   //     console.log('Element is fully visible in screen');
  //   //
  //   //     var a = document.getElementsByTagName('html')[0]
  //   //     a.style.overflow = 'hidden';
  //   //
  //   //
  //   //   }
  //   // }
  // };
  // const container = document.querySelector(".slider-section");
  // const slideArea = document.querySelector(".slick-slider");
  // function onFocus(e) {
  //   const { left, right } = e.target.getBoundingClientRect();
  // }
  // useEffect(() => {
  //   // const sliderFocus = document.getElementById("slider")
  //   // if(sliderFocus?.focus()){
  //   //   window.addEventListener("wheel", scroll, true);
  //   // }
  //   slideArea?.addEventListener("focus", onFocus, true);
  //   // window.addEventListener("wheel", scroll, true);
  //   // window.addEventListener("focus", scroll, true);

  //   return () => {
  //     // window.removeEventListener("wheel", scroll, true);
  //     // window.removeEventListener("focus", scroll, true);
  //   };
  // }, []);

  return (
    <div
      className="slider-section"
    // id="slider"
    // onFocus={scroll}
    >
      <input
        onChange={(e) => e.target.value}
        value={slideIndex}
        type="range"
        step={1}
        min={0}
        max={arr.length - 1}
      />
      <div className="slider-section_header">
        <h6>{translate(heading)}</h6>
        <h2 className="item-header">{translate(title)}</h2>
      </div>
      <Slider ref={slider} {...settings}>
        {arr.map((item, index) => (
          <div key={index}>
            <div className="grid">
              <div className="item">
                <div className="item-content">
                  <h3>{translate(item.name)}</h3>
                  <p id="item-text">{translate(item.content)}</p>
                  <p id="item-text">{translate(item.content2!)}</p>
                </div>
              </div>
              <div className="item">
                <div className="img-wrap">
                  <img
                    src={item.img.src}
                    alt="slider_picture"
                    draggable="false"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Sliders;
