import React, { FC, useContext, useEffect } from "react";
import LangState, { LangContext } from "../context/lang";

import { observer } from "mobx-react";
import ButtonsDownload from "./ButtonsDownload";

import Logo from "../img/logo-footer.png";

import "../scss/_insuranceJoinNow.scss";

// Preparation for content
interface JoinCountersProps {
  title_main: string;
  content_main: string;
  conter_top: string;
  counters_title: JSX.Element | null;
  conter_top2: string;
  conter_bottom0: string;
  conter_bottom: string;
  topblock_top: string;
  topblock_bottom: string;
  bottomblock_top: string;
  bottomblock_bottom: string;
  logo_boolean?: boolean;
}

const JoinCounters: FC<JoinCountersProps> = observer(
  ({
    title_main,
    content_main,
    conter_top,
    counters_title,
    conter_top2,
    conter_bottom0,
    conter_bottom,
    topblock_top,
    topblock_bottom,
    bottomblock_top,
    bottomblock_bottom,
    logo_boolean,
  }) => {
    const {
      state: { language },
      dispatch: { setLanguage, translate },
    } = useContext(LangContext);

    return (
      <div className="joincounters">
        <div className="cont cont_md">
          <div className="grid">
            <div className="item">
              <div className="item-top">
                <h2>{title_main}</h2>
                <p>{content_main}</p>
              </div>

              <ButtonsDownload />
            </div>
            <div className="item">
              <div className="grid counters">
                <div className="item-counters">
                  <p className="item-counters_subtitle">{conter_top}</p>
                  {counters_title}
                  <p className="item-counters_subtitle">{conter_top2}</p>
                  <div className="item-counters-bottom">
                    {language === "TR" ? (
                      <p className="item-counters_subtitle tr">
                        {conter_bottom0}
                      </p>
                    ) : null}

                    {logo_boolean == false &&
                      (language !== "UK" ? (
                        <img
                          src={Logo}
                          alt="logo"
                          id="joinnow-logo"
                          draggable="false"
                        />
                      ) : null)}
                    {logo_boolean == true ? (
                      <img
                        src={Logo}
                        alt="logo"
                        id="joinnow-logo"
                        draggable="false"
                      />
                    ) : null}
                    <p className="item-counters_subtitle">{conter_bottom}</p>
                  </div>
                </div>
                <div className="item-bloks">
                  <div className="grid-bloks">
                    <div className="item trusted">
                      <p className="item-trusted_subtitle">{topblock_top}</p>
                      <h4 className="item-trusted_title">{topblock_bottom}</h4>
                    </div>
                    <div className="item secure">
                      <h4 className="item-secure_title">{bottomblock_top}</h4>
                      <p className="item-secure_subtitle">
                        {bottomblock_bottom}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default JoinCounters;
