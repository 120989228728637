import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";

import "../../scss/_homePartner.scss";
import ButtonsDownload from "../ButtonsDownload";

import Img1 from "../../img/home/partner/registr.png";
import Img2 from "../../img/home/partner/promote.png";
import Img3 from "../../img/home/partner/create.png";
import Img4 from "../../img/home/partner/payments.png";
// import Img5 from "../../img/bookpay/services/serv5.png";
import iPhone from "../../img/home/partner/iPhone_partner.png";

import AppStore from "../../img/AppStore.png";
import GooglePlay from "../../img/GooglePlay.png";

const HomePartner: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <section className="home_partner">
      <svg
        width="1920"
        height="356"
        viewBox="0 0 1920 356"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-d"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.5"
          d="M1920 231.381C1725.63 85.818 1566.92 -11.8886 1324.5 2.38089C918.461 26.2818 1019.82 333.074 618 270C377.984 232.325 188.5 397.381 0 343.881"
          stroke="#F7B500"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <svg
        width="834"
        height="356"
        viewBox="0 0 834 356"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-t"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.5"
          d="M1395 231.381C1200.63 85.818 1041.92 -11.8886 799.5 2.38089C393.461 26.2818 494.821 333.074 93 270C-147.016 232.325 -336.5 397.381 -525 343.881"
          stroke="#F7B500"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <div className="cont cont_sm">
        <h6>{translate("home_partner_name")}</h6>
        <div className="container">
          <h2 id="feature">{translate("home_partner_bigtitle")}</h2>
          <p className="home_partner_subtitle">
            {translate("home_partner_subtitle")}
          </p>
          <div className="btn-download">
            <a href="https://apps.apple.com/app/id1551798314" target="_blank">
              <button className="btn appstore">
                <img src={AppStore} alt="AppStore" draggable="false" />
              </button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.beep.partner"
              target="_blank"
            >
              <button className="btn googleplay">
                <img src={GooglePlay} alt="GooglePlay" draggable="false" />
              </button>
            </a>
          </div>
          <div className="contant">
            <div className="home_partner-grid">
              <div className="block_info">
                <ul>
                  <li>
                    <div className="li_img">
                      <img
                        src={Img1}
                        alt="create"
                        className="home_partner_img1"
                        draggable="false"
                      />
                    </div>
                    <p>{translate("home_partner_li_first")}</p>
                  </li>
                  <li>
                    <div className="li_img">
                      <img
                        src={Img2}
                        alt="create"
                        className="home_partner_img2"
                        draggable="false"
                      />
                    </div>
                    <p>{translate("home_partner_li_second")}</p>
                  </li>
                  <li>
                    <div className="li_img">
                      <img
                        src={Img3}
                        alt="create"
                        className="home_partner_img3"
                        draggable="false"
                      />
                    </div>
                    <p>{translate("home_partner_li_third")}</p>
                  </li>
                  <li>
                    <div className="li_img">
                      <img
                        src={Img4}
                        alt="create"
                        className="home_partner_img4"
                        draggable="false"
                      />
                    </div>
                    <p>{translate("home_partner_li_fourth")}</p>
                  </li>
                </ul>
              </div>
              <div className="block-img">
                <img src={iPhone} alt="iPhone" draggable="false" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePartner;
