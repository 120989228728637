import React from 'react';
import "../../../scss/_autoServicePartner.scss";
import { UK_CODE } from '../data';

interface InputProps {
    id: string;
    label: string;
    value: string;
    onChange: (id: string, value: string) => void;
    placeholder?: string;
    error: boolean;
    errorMessage: string;
    formSubmitted: boolean;
    maxLength?: number;
}

const Input: React.FC<InputProps> = ({
    id,
    label,
    value,
    onChange,
    placeholder,
    error,
    errorMessage,
    formSubmitted,
    maxLength
}) => {

    let className = "form-control";

    if (formSubmitted && error) {
        className += " input-error";
    }

    return (
        <div key={id} className="form-group">
            <label className="form-label" htmlFor={id}>{label}</label>

            <input
                id={id}
                className={className}
                aria-describedby="inputHelp"
                value={value || ''}
                onChange={(event) => {
                    let inputValue = event.target.value;
                    if ((id === 'phone' || id === 'authPhone')) {
                        if (inputValue.length === 1 && inputValue !== '+') {
                            onChange(id, UK_CODE + inputValue);
                        } else if (inputValue.length >= 4 && inputValue.substring(0, 4) !== UK_CODE) {
                            onChange(id, UK_CODE + inputValue);
                        } else {
                            onChange(id, inputValue);
                        }
                    } else {
                        onChange(id, inputValue);
                    }
                }}
                placeholder={placeholder}
                maxLength={maxLength}
            />

            {formSubmitted && error && <div className="input_error">{errorMessage}</div>}

        </div>
    );
};

export default Input;
