import React, { FC, useContext, useEffect, useState } from "react";
import LangState, { LangContext } from "../../context/lang";
import ReactPlayer from "react-player/youtube";
import { Button, Box, Typography, Modal } from "@mui/material";

import { observer } from "mobx-react";
import StoreCounters from "../../store/counters.store";

import "../../scss/_parkingServices.scss";

import Img1 from "../../img/parking/services/serv1.png";
import Img2 from "../../img/parking/services/serv2.png";
import Img3 from "../../img/parking/services/serv3.png";
import logoen from "../../img/en/parking/services/bMembers_en.png";
import logouk from "../../img/uk/parking/services/bMembers_ua.png";
import logotr from "../../img/tr/parking/services/byesi_tr.png";
// import video from "../../img/parking/services/video.png";
import video from "../../img/parking/services/btn-video.png";

// image "beep!Members"
const images = {
  EN: {
    img: logoen,
  },
  UK: {
    img: logouk,
  },
  TR: {
    img: logotr,
  },
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 969,
  p: 4,
};

type Lang = "EN" | "UK" | "TR";

const ParkingServices: FC = observer(() => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Hook for work with store and DataBase of firebase with mobx
  useEffect(() => {
    StoreCounters.onCounters();

    return function cleanup() {
      StoreCounters.offCounters();
    };
  }, []);

  //Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Play/pause
  const [clicked, setClicked] = useState<boolean>(false); // button video -> show player
  const [hide, setHide] = useState<string>("inherit"); // change style for hiding player
  const [show, setShow] = useState<boolean>(true); // button close -> hide player

  // Style for div 'parking-player'
  const divstyle = {
    display: hide,
  };

  // Hide/Show player -> Change style for div 'parking-player'
  useEffect(() => {
    if (!show) {
      setHide("hide");
      setClicked(false);
    }
    if (!clicked) {
      setHide("inherit");
      setShow(true);
    }
  });

  return (
    <section className="parking_services">
      <div className="cont cont_md">
        <h6>{translate("parking_services_name")}</h6>
        <h2 id="feature">{translate("parking_services_bigtitle")}</h2>

        <div className="grid">
          <div className="first">
            <div className="img-wrap">
              <img src={Img1} alt="service1" draggable="false" />
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p>
                  {translate("parking_services_title1")}
                  {StoreCounters.membersCount !== null ? (
                    <span>{StoreCounters.membersCount}</span>
                  ) : null}
                  {translate("parking_services_title1_2")}
                  &nbsp;
                </p>
                {" "}
              {language &&
                (language !== "EN" ? (
                  <img
                  className="parking_services_logo-uk"
                  src={images[language as Lang].img}
                  alt="logo"
                  draggable="false"
                  // style={uklogostyle}
                />
                ) : (
                <img
                  className="parking_services_logo-en"
                  src={images[language as Lang].img}
                  alt="logo"
                  draggable="false"
                  // style={enlogostyle}
                />
                ))}

              </div>
              <div className="servise-subtitle">
                <p>{translate("parking_services_subtitle1")}</p>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="img-wrap">
              <img src={Img2} alt="service2" draggable="false" />
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("parking_services_title2")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("parking_services_subtitle2")}</p>
              </div>
            </div>
          </div>
          <div className="third">
            <div className="img-wrap">
              <img src={Img3} alt="service3" draggable="false" />
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("parking_services_title3")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("parking_services_subtitle3")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="parking_video_container">
          <div className="img-video">
            <p>
              {" "}
              {language &&
                (language !== "UK" ? (
                  <img
                    className="video-btn"
                    src={video}
                    alt="svideo-btn"
                    draggable="false"
                    onClick={handleOpen}
                    // onClick={() => setClicked(true)}
                  />
                ) : (
                  // <img />
                  null
                ))}
              {translate("parking_services_videosubtitle")}
            </p>
          </div>
        </div>
        {/* <div id="parking-player" > */}
        <div id="parking-player" style={divstyle}>
          {
            // clicked &&
            language === "EN" ? (
              <div>
                <Modal keepMounted open={open} onClose={handleClose}>
                  <Box sx={style}>
                    <ReactPlayer
                      url="https://youtu.be/NnWfVJXwmVc"
                      playing={open}
                      light={true}
                    />
                  </Box>
                </Modal>
              </div>
            ) : language === "TR" ? (
              <div>
                <Modal keepMounted open={open} onClose={handleClose}>
                  <Box sx={style}>
                    <ReactPlayer
                      url="https://youtu.be/NnWfVJXwmVc"
                      playing={open}
                      // playing={clicked}
                      light={true}
                      volume={0.5}
                      stopOnUnmount={true}
                    />
                  </Box>
                </Modal>
              </div>
            ) : null
          }
          {
            // clicked &&
            // language !== "UK" ? (
            //   <Button onClick={() => setShow(false)} className="close-button">
            //     PAUSE
            //   </Button>
            // ) : null
          }
        </div>
      </div>
    </section>
  );
});

export default ParkingServices;
