import React, { useState, useEffect, useContext } from "react";
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { ToastContainer, toast } from "react-toastify";

import { ThemeProvider } from '@mui/material/styles';
import { Box, Button, FormControl } from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';

import { IServicePoint } from './interfaces';
import { IAddress } from './interfaces/IServicePoint';
import { FieldType, IFieldState, OptionType, initialFields } from './data';
import StoreAutoServicePartner from '../../store/auto-service-partner.store'
import StoreAuth from '../../store/auth.store';
import Textarea from './components/Textarea';
import Service from "../../img/uk/auto-service/auto_service.png";
import Input from './components/Input';
import SimpleSelect from './components/Select';
import MuiOtpInputModal from './components/MuiOtpInput';
import ImageUploadButton, { IImage } from './components/ImageUploadButton';
import InputAutocomplete from './components/InputAutocomplete';
import { LangContext } from '../../context/lang';
import { btnSubmitStyles, theme } from './styles';
import "../../scss/_autoServicePartner.scss";

const AutoServiceForm = observer(({ translate }) => {
  const { state: { language } } = useContext(LangContext);

  const [fields, setFields] = useState<IFieldState[]>(initialFields);
  const [carsMake, setCarsMake] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<IImage[]>([]);
  const [savedServicePoint, setSavedServicePoint] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const result = await StoreAutoServicePartner.getMakeCars()
      if (result) {
        setCarsMake(result);
      }
    })()
    setLoading(false)
  }, [])

  useEffect(() => {
    setFields(prevFields =>
      prevFields.map(field =>
        field.id === 'vehicleBrand' ? { ...field, options: carsMake } : field
      )
    );
  }, [carsMake])

  const validateField = (field) => {
    setFormSubmitted(false)
    let isValid = true;
    let errorMessage = '';
    if (field.validations) {
      if (field.id === 'address' && !field.value?.name?.[language.toLowerCase()]) {
        isValid = false;
        errorMessage = translate('сar_srvc_partner_error_empty');
      }
      else if (field.validations.isPhoneNumber) {
        const digitsOnly = /^\+38\s?\(?[0][0-9]{2}\)?\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/;
        if (!digitsOnly.test(field.value)) {
          isValid = false;
          errorMessage = translate(field.validations.errorMessage);
        }
      }
      else if (field.validations.isEmpty && (!field.value || !field.value.length)) {
        isValid = false;
        errorMessage = translate('сar_srvc_partner_error_empty');
      }
    }

    return {
      ...field,
      isValid,
      validations: {
        ...field.validations,
        errorMessage,
      },
    };
  }

  const handleChange = (id: string, value: string | string[] | IAddress) => {
    if (id === 'authPhone' && typeof value === 'string') {
      const phone = value.replace(/\D/g, '');
      setPhoneNumber(phone);
    }

    setFields((prevFields) =>
      prevFields.map((field) => {
        if (id.startsWith('workingTime.time') && field.id === 'workingTime') {
          const time = {
            ...field,
            time: field.time.map((timeField) =>
              timeField.id === id ? validateField({ ...timeField, value, isTouched: true }) : timeField
            ),
          }
          return time
        }
        else if (field.id === id) {
          return validateField({ ...field, value });
        }

        else {
          return field;
        }
      })
    );
  };

  const createOptionValue = (id: string, options: OptionType[]): OptionType => {
    const option = options.find((option) => option.id === id);
    return { id, value: option ? option.value : '' };
  }

  const createServicePoints = (fields: FieldType[]): IServicePoint => {
    const servicePoint: Partial<IServicePoint> = {};

    fields.forEach((field) => {
      if (field.id === 'address') {
        servicePoint[field.id] = field.value as IAddress;
      }
      else if (field.id === 'phone') {
        const phone = field.value as string;
        const code = phone.slice(0, 4);
        const number = phone.slice(4);

        servicePoint[field.id] = number
        servicePoint['code'] = code
      }
      else if (field.id === 'vehicleType' || field.id === 'vehicleBrand') {
        (servicePoint[field.id] as OptionType[]) = (field.value as string[]).map((id) => createOptionValue(id, field.options as OptionType[]));
      }
      else if (field.id === 'workingTime') {
        let from = '';
        let to = '';

        field.time?.forEach(timeField => {
          if (timeField.id === 'workingTime.time.from') {
            from = timeField.value as string;
          } else if (timeField.id === 'workingTime.time.to') {
            to = timeField.value as string;
          }
        });

        servicePoint[field.id] = {
          days: field.value as string[],
          time: { from, to },
        };
      }
      else {
        servicePoint[field.id] = field.value;
      }
    });

    return servicePoint as IServicePoint;
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true)
      const servicePoints = createServicePoints(fields)

      const saveServicePromise = StoreAutoServicePartner.processAuthAndUpload(otp, selectedFiles, servicePoints)
      toast.promise(saveServicePromise, {
        pending: translate('car_srvc_partner_form_saving'),
        error: translate('car_srvc_partner_form_saving_error')
      });

      const resultSaveService = await saveServicePromise;

      if (resultSaveService.error) {
        if (resultSaveService.error === 'The code is incorrect') {
          toast.error(translate('car_srvc_partner_form_incorrect_code'));
        }
      } else {
        handleClose()
        setSavedServicePoint(resultSaveService)
      }
    } catch (error) {
      console.log('handleSubmit => error:', error);
      toast.error(translate('car_srvc_partner_form_saving_error'));
    } finally {
      setLoading(false)
    }
  }

  const getSmsCode = async (): Promise<void> => {
    let newFields = fields.map((field) => validateField(field));

    setFields(newFields);

    const hasValidationErrors = newFields.some(field => {

      if (field.id === 'address' && field.value?.name?.[language.toLowerCase()]) {
        field.isValid = true;
      }

      if (!field.isValid) return true;

      if (field.time && field.time.length > 0) {
        return field.time.some(timeField => !timeField.isValid);
      }

      return false;
    });

    if (!hasValidationErrors && selectedFiles.length) {
      await StoreAuth.authSmsGet(phoneNumber);
      handleOpen();
    } else {
      setFormSubmitted(true);
    }
  };

  const addNewServicePointAgain = () => {
    setSavedServicePoint(!savedServicePoint)
    setFields(initialFields)
    setSelectedFiles([])
    setOtp('')
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        {savedServicePoint
          ? (
            <Box sx={{ height: 1000 }}>
              <div className="auto_service_partner">
                <div className="title">
                  <h2>
                    {translate('сar_srvc_partner_title_2')}
                  </h2>
                  <p>{translate('сar_srvc_partner_text_2')}</p>
                </div>

                <Button
                  sx={{
                    backgroundColor: '#f7b500',
                    alignSelf: 'start',
                    '&:hover': {
                      backgroundColor: '#f7b50090'
                    },
                    color: '#131518',
                    padding: '18px 20px',
                    borderRadius: '15px',
                    // marginLeft: '28px',
                    marginTop: '20px',
                    textTransform: 'none',
                    fontSize: '18px',
                    '&:disabled': {
                      backgroundColor: '#f7b50060',
                      color: '#131518',
                    },
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={addNewServicePointAgain}
                >
                  {translate('сar_srvc_partner_add_btn')}
                </Button>

                <div className="auto_service_img hidden">
                  <img src={Service} alt="ParkingHero" draggable="false" />
                </div>
              </div>
            </Box>
          ) : (
            <div className="auto_service_partner">
              <div className="title">
                <h2>
                  {translate('сar_srvc_partner_title')}
                </h2>
                <p>
                  {translate('сar_srvc_partner_text')}
                </p>
                <h6>
                  {translate('сar_srvc_partner_text_highlt')}
                </h6>
              </div>

              <div className="auto_service_img hidden">
                <img src={Service} alt="ParkingHero" draggable="false" />
              </div>

              <div className="service_form">
                <form id="contact-form" onSubmit={handleSubmit} method="POST">
                  {!loading ? fields?.map((field: IFieldState) => {

                    if (field.type === 'text') {
                      if (field.id === 'address') {
                        return (
                          <InputAutocomplete
                            key={field.id}
                            id={field.id}
                            label={translate(field.label)}
                            value={field.value as IAddress}
                            onChange={handleChange}
                            errorMessage={field.validations?.errorMessage || ''}
                            error={field.isValid === false}
                            formSubmitted={formSubmitted}
                          />
                        )
                      } else {

                        return (
                          <Input
                            key={field.id}
                            id={field.id}
                            label={translate(field.label)}
                            value={(field.value as string) || ''}
                            onChange={handleChange}
                            placeholder={field?.placeholder}
                            errorMessage={field.validations?.errorMessage || ''}
                            maxLength={field?.validations?.maxLength}
                            error={field.isValid === false}
                            formSubmitted={formSubmitted}
                          />
                        );
                      }

                    } else if (field.type === 'textarea') {
                      return (
                        <Textarea
                          key={field.id}
                          id={field.id}
                          label={translate(field.label)}
                          value={(field.value as string) || ''}
                          onChange={event => handleChange(field.id, event.target.value)}
                        />
                      );
                    }
                    else if (field.type === 'select') {
                      return (
                        <SimpleSelect
                          key={field.id}
                          id={field.id}
                          label={translate(field.label)}
                          value={field?.value as string[] || []}
                          options={field.options || []}
                          errorMessage={field.validations?.errorMessage || ''}
                          onChange={handleChange}
                          error={field.isValid === false}
                          formSubmitted={formSubmitted}
                          translate={translate}
                        />
                      );
                    }
                    else if (field.type === 'days') {

                      return (
                        <Box key={field.id} sx={{ display: 'flex', flexDirection: 'column' }}>
                          <SimpleSelect
                            key={field.id}
                            id={field.id}
                            label={translate(field.label)}
                            value={field?.value as string[] || []}
                            options={field.options || []}
                            errorMessage={field.validations?.errorMessage || ''}
                            error={field.isValid === false}
                            onChange={handleChange}
                            formSubmitted={formSubmitted}
                            translate={translate}
                          />

                          <Box sx={{ display: 'flex', justifyContent: 'space-between', height: 121 }}>
                            {field.time?.map((time, index) => {
                              return (
                                <Box key={index} sx={{ display: 'flex', marginRight: index - 1 ? '30px' : 0 }}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor={time.id}>{translate(time.label)}</label>
                                    <FormControl>
                                      <DemoItem component='TimeField'>
                                        <TimeField
                                          label=' '
                                          id={time.id}
                                          format="HH:mm"
                                          onChange={(value) => {
                                            if (dayjs.isDayjs(value) && value.isValid()) {
                                              let timeString = value.format("HH:mm");
                                              handleChange(time.id, timeString)
                                            }
                                          }}
                                          sx={{
                                            '& .MuiOutlinedInput-input': {
                                              backgroundColor: '#343538',
                                              borderRadius: '15px',
                                              color: '#fff',
                                              px: '19px',
                                              py: '13px',
                                              maxWidth: '188px',
                                              borderColor: formSubmitted && time?.isValid === false ? '#ff1414 !important' : null,
                                            },
                                            '& .MuiOutlinedInput-root': {
                                              borderRadius: '15px',
                                              maxWidth: '188px',
                                            },
                                            '&.Mui-focused': {
                                              borderColor: '#F7B500 !important',
                                              p: '26px 19px',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#F7B500 !important',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#343538 !important',
                                            },
                                          }}
                                        />
                                        {formSubmitted && time.isValid === false && <div className="input_error">{translate('сar_srvc_partner_error_empty')}</div>}
                                      </DemoItem>
                                    </FormControl>

                                  </div>
                                </Box>
                              )
                            })}
                          </Box>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  }) : <></>}

                  <ImageUploadButton
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    formSubmitted={formSubmitted}
                    error={selectedFiles.length === 0}
                    translate={translate}
                  />

                  <Button
                    sx={btnSubmitStyles}
                    variant="contained"
                    color="secondary"
                    onClick={getSmsCode}
                  >
                    {translate('сar_srvc_partner_publish_btn')}
                  </Button>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  <MuiOtpInputModal
                    open={open}
                    handleClose={handleClose}
                    phoneNumber={phoneNumber}
                    handleSubmit={handleSubmit}
                    otp={otp}
                    setOtp={setOtp}
                    loading={loading}
                    translate={translate}
                  />
                </form>
              </div>
            </div>
          )}
      </LocalizationProvider>
    </ThemeProvider>
  );
});

export default AutoServiceForm;
