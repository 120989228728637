import {action, observable, computed, makeObservable} from 'mobx';
import {ref, onValue, off} from "firebase/database";
import sortBy from 'lodash/sortBy';

import {RealtimeDB} from '../firebase';
import {IFeedback} from "./interfaces";

class StoreFeedbacksBookpay {
    constructor() {
        makeObservable(this);
    }

    private debug: boolean = false;

    private log(type: 'log' | 'warn' | 'error', message?: any,  ...optionalParams: any[]) {
        if (this.debug) {
            console[type](message, ...optionalParams);
        }
    }

    @observable public list: Array<IFeedback> = [];

    @action MutationList(data: Array<IFeedback>) {
        this.log('log', 'StoreFeedbacks-bookpay.MutationList data:', data);
        this.list = sortBy(data, 'date');
    }

    onFeedbacks() {
        this.log('log', 'StoreFeedbacks-bookpay.onFeedbacks');

        const feedbacksRef = ref(RealtimeDB, '/feedbacks-bookpay');
        onValue(feedbacksRef, (snapshot => {
            const list: IFeedback[] = [];
            snapshot.forEach((childSnapshot) => {
                list.push({...childSnapshot.val()});
            });
            this.MutationList(list);
        }));
    }

    offFeedbacks() {
        this.log('log', 'StoreFeedbacks-bookpay.offFeedbacks');
        const feedbacksRef = ref(RealtimeDB, '/feedbacks-bookpay');
        off(feedbacksRef);
    }
}

export default new StoreFeedbacksBookpay();
