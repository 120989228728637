import React, { FC, useContext, useEffect, useRef } from "react";
import LangState, { LangContext } from "../context/lang";
import Slider from "react-slick";
import { observer } from "mobx-react";
import { Languages } from "../store/interfaces/enums";
import IFeedback from "../store/interfaces/IFeedback";

import ImgStar from "../img/insurance/reviews/star.png";

import "../scss/_insuranceReviews.scss";

interface IFeedbackProps {
  item: Array<IFeedback>;
}

const Reviews: FC<IFeedbackProps> = observer(({ item }) => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Main slider display settings
  let settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 600,
    // autoplay: true,
    touchMove: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // Scroll
  const slider = useRef<any>(null);

  function scroll(e) {
    if (slider === null) return 0;

    e.wheelDelta > 0 ? slider.current.slickNext() : slider.current.slickPrev();
  }

  useEffect(() => {
    // window.addEventListener("wheel", scroll, true);

    return () => {
      // window.removeEventListener("wheel", scroll, true);
    };
  }, []);

  return (
    <div className="reviews-sliders">
      {item.length ? (
        <Slider {...settings} ref={slider}>
          {item.map((item) => (
            <div key={item.id}>
              <div className="grid">
                <div className="item">
                  <div className="grid items-top">
                    <div className="item-content1">
                      <img
                        className="review-photo"
                        src={item.avatar}
                        alt="review-avatar"
                        draggable="false"
                      />
                    </div>
                    <div className="item-content2">
                      <h5>{item.fullName[language.toLocaleLowerCase() as Languages]}</h5>
                      <div className="auto">
                        <img
                          className="review-auto"
                          src={item.vehicleLogo}
                          alt="vehicle-auto"
                          draggable="false"
                        />
                        <p id="item-text">{item.vehicle}</p>
                      </div>
                    </div>
                  </div>
                  <div className="text">
                    {item.comment[language.toLocaleLowerCase() as Languages]}
                  </div>
                  <div className="grid items-bottom">
                    <div className="item-content3">
                      <img
                        className="review-star"
                        src={ImgStar}
                        alt="star"
                        draggable="false"
                      />
                      <p>{item.rating}</p>
                    </div>
                    <div className="item-content2">
                      <p id="item-date">{item.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
});

export default Reviews;
