import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";

import "../../scss/_bookpayServices.scss";

import Img1 from "../../img/bookpay/services/serv1.png";
import Img2 from "../../img/bookpay/services/serv2.png";
import Img3 from "../../img/bookpay/services/serv3.png";
import Img4 from "../../img/bookpay/services/serv4.png";
import Img5 from "../../img/bookpay/services/serv5.png";
import Img6 from "../../img/bookpay/services/serv6.png";

const BookpayServices: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <section className="bookpay_services">
      <div className="cont cont_md">
        <h6>{translate("bookpay_services_name")}</h6>
        <h2 id="feature">{translate("bookpay_services_bigtitle")}</h2>

        <div className="grid">
          <div className="first">
            <div className="img-wrap">
              <img src={Img1} alt="service1" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title1")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle1")}</p>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="img-wrap">
              <img src={Img3} alt="service3" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title3")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle3")}</p>
              </div>
            </div>
          </div>
          <div className="third">
            <div className="img-wrap">
              <img src={Img5} alt="service5" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title5")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle5")}</p>
              </div>
            </div>
          </div>
          <div className="fourth">
            <div className="img-wrap">
              <img src={Img2} alt="service2" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title2")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle2")}</p>
              </div>
            </div>
          </div>
          <div className="fifth">
            <div className="img-wrap">
              <img src={Img4} alt="service4" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title4")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle4")}</p>
              </div>
            </div>
          </div>
          <div className="sixth">
            <div className="img-wrap">
              <img src={Img6} alt="service6" draggable="false"/>
            </div>
            <div className="text-cont">
              <div className="servise-title">
                <p> {translate("bookpay_services_title6")} </p>
              </div>
              <div className="servise-subtitle">
                <p>{translate("bookpay_services_subtitle6")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookpayServices;
