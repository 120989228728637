import React, { FC } from "react";
import NaviBar from "../components/NaviBar";
import Footer from "../components/Footer";

interface IProps {
  children: any;
}

const TemplatePage: FC<IProps> = (props) => {
  return (
    <div className="content-wrap">
      <NaviBar />
        {props.children}
      <Footer />
    </div>
  );
};

export default TemplatePage;
