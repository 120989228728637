import React, { FC, useContext, useEffect, useState } from "react";
import { LangContext } from "../../context/lang";
import enmain from "../../img/insurance/hero/eng1.png";
import insHeroPrev from "../../img/insurance/hero/insurance.png";
import ukmain from "../../img/insurance/hero/ua1.png";
import trmain from "../../img/insurance/hero/turkish1.png";
import enmain1 from "../../img/en/insurance/hero/enInsHero1.png";
import enmain2 from "../../img/en/insurance/hero/enInsHero2.png";
import ukmain1 from "../../img/uk/insurance/hero/ukInsHero1.png";
import ukmain2 from "../../img/uk/insurance/hero/ukInsHero2.png";
import trmain1 from "../../img/tr/insurance/hero/trInsHero1.png";
import trmain2 from "../../img/tr/insurance/hero/trInsHero2.png";
import circles1 from "../../img/insurance/hero/InsHeroBg1.png";
import circles2 from "../../img/insurance/hero/InsHeroBg2.png";
import find from "../../img/insurance/hero/find.png";
// import { NavLink } from "react-router-dom";
// import cookies from 'js-cookie'
// import { useTranslation } from "react-i18next";
// import logo from "../img/logo.png";

import "../../scss/_insuranceHero.scss";

// image0 - central text, images 1,2 - side images
const images = {
  EN: {
    img0: enmain,
    img1: enmain1,
    img2: enmain2,
  },
  UK: {
    img0: ukmain,
    img1: ukmain1,
    img2: ukmain2,
  },
  TR: {
    img0: trmain,
    img1: trmain1,
    img2: trmain2,
  },
};

type Lang = "EN" | "UK" | "TR";

const Hero: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  return (
    <>
      <section className="ins_hero">
        <svg
          width="1920"
          height="726"
          viewBox="0 0 1920 726"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-d"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.25"
            d="M0.463642 61.0246C93.1476 25.173 182.656 -22.3546 292.61 14.8769C588.311 115.004 489.715 271.003 750.239 505.962C1010.76 740.92 1335.46 784.219 1659.72 645.434C1739.25 611.393 1828.56 562.57 1920.62 503.198"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <svg
          width="1023"
          height="277"
          viewBox="0 0 1023 277"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-t"
        >
          <path
             data-v-3612c691=""
             className="path"
            opacity="0.25"
            d="M5.61668e-05 23.8492C35.1115 10.2676 69.0202 -7.7373 110.674 6.36712C222.694 44.2983 185.343 103.396 284.037 192.405C382.732 281.414 505.738 297.817 628.575 245.241C658.704 232.345 692.538 213.85 727.414 191.358"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <div className="content-wrap">
          <div className="hero-img_cont">
            <img
              className="hero-img_prev"
              src={insHeroPrev}
              alt="InsHeroPrev"
              draggable="false"
            />
            <img
              className="hero-img"
              src={images[language as Lang].img0}
              alt="InsHero"
              draggable="false"
            />
          </div>
          <a
              href="https://app.beep.club/website"
              target="_blank"
            >
          <button className="btn">{translate("hero_btn_insurance")}</button>
         </a>
          <p className="bottom_subtitle">
            <img src={find} alt="Find" draggable="false" />
            {translate("hero_text1")}
            <a href="https://apps.apple.com/app/id1505288985" target="_blank"> AppStore </a> {translate("hero_text2")}
            <a href="https://play.google.com/store/apps/details?id=com.bac.client" target="_blank"> GooglePlay</a>
            {translate("hero_text3")}
          </p>
        </div>

        <div className="back">
          <div className="img-wrap heroFadeUp">
            <img
              src={images[language as Lang].img1}
              draggable="false"
              alt="InsHero1"
            />
          </div>
          <div className="img-wrap heroFadeUp">
            <img
              src={images[language as Lang].img2}
              draggable="false"
              alt="InsHero2"
            />
          </div>
          <div className="img-wrap heroFadeUp">
            <img src={circles1} alt="InsHeroBg1" draggable="false" />
          </div>
          <div className="img-wrap heroFadeUp">
            <img src={circles2} alt="InsHeroBg2" draggable="false" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
