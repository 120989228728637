import React, { FC } from "react";
import { Link } from "react-router-dom";
import TemplatePage from "./TemplatePage";

interface Page404Props {
  translate: (key: string) => string;
}

const Page404: FC<Page404Props> = ({ translate }) => {
  return (
    <TemplatePage>
      <section className="page-404">
        <div className="container">
          <h1 className="testing">404</h1>
          <p>
            <Link to="/">{translate("returnToHomepage")}</Link>
          </p>
        </div>
      </section>
    </TemplatePage>
  );
};

export default Page404;
