import React, { FC, useState, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";

import "../../scss/_insuranceFAQ.scss";
import LangState, { LangContext } from "../../context/lang";

const FAQ: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Closing panels that are not in use
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section className="insurance_faq">
      <div className="cont cont_md">
        <div className="">
          <h6>{translate("insurance_faq_sub")}</h6>
          <h2>{translate("insurance_faq_title")}</h2>
        </div>
        <div className="accordions">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel1")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question1")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer1")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel2")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel2" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question2")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer2")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel3")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel3" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question3")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer3")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel4")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel4" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel4-content"
              id="panel3-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question4")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer4")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel5")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel5" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel5-content"
              id="panel3-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question5")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer5")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel6")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel6" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel6-content"
              id="panel6-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question6")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer6")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={(event, isExpanded) => handleChange(isExpanded, "panel7")}
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel7" ? (
                  <Remove style={{ color: "#FFFFFF80" }} />
                ) : (
                  <Add style={{ color: "#F7B500" }} />
                )
              }
              aria-controls="panel7-content"
              id="panel7-header"
            >
              <Typography className="faq-title">
                {translate("insurance_faq_question7")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq-answer">
                {translate("insurance_faq_answer7")}
              </Typography>
                <ul>
                  <li className="faq-li">
                    {translate("insurance_faq_answer7_li1")}
                  </li>
                  <li>{translate("insurance_faq_answer7_li2")}</li>
                  <li>{translate("insurance_faq_answer7_li3")}</li>
                  <li>{translate("insurance_faq_answer7_li4")}</li>
                </ul>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
