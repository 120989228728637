import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";
import Sliders from "../Sliders";

import Img1En from "../../img/en/bp/slider/enBpSlider1.png";
import Img2En from "../../img/en/bp/slider/enBpSlider2.png";
import Img3En from "../../img/en/bp/slider/enBpSlider3.png";
import Img1Uk from "../../img/uk/bp/slider/ukBpSlider1.png";
import Img2Uk from "../../img/uk/bp/slider/ukBpSlider2.png";
import Img3Uk from "../../img/uk/bp/slider/ukBpSlider3.png";
import Img1Tr from "../../img/tr/bp/slider/trBpSlider1.png";
import Img2Tr from "../../img/tr/bp/slider/trBpSlider2.png";
import Img3Tr from "../../img/tr/bp/slider/trBpSlider3.png";

import "../../scss/_bookpaySlider.scss";
import { ISlidersProps } from "../interfaces/ISlidersProps";

// Images for Grid. src
const images = {
    EN: {
        img1: Img1En,
        img2: Img2En,
        img3: Img3En,
    },
    UK: {
        img1: Img1Uk,
        img2: Img2Uk,
        img3: Img3Uk,
    },
    TR: {
        img1: Img1Tr,
        img2: Img2Tr,
        img3: Img3Tr,
    },
};

type Lang = "EN" | "UK" | "TR";

const BookpaySlider: FC = () => {
    const {
        state: { language },
        // dispatch: { setLanguage, translate },
    } = useContext(LangContext);

    // Array to fill with slider content
    const sliders: ISlidersProps[] = [
        {
            name: "bookpay_slider_name1",
            content: "bookpay_slider_content1",
            img: {
                src: images[language as Lang].img1,
                alt: "search",
            },
        },
        {
            name: "bookpay_slider_name2",
            content: "bookpay_slider_content2",
            img: {
                src: images[language as Lang].img2,
                alt: "choice",
            },
        },
        {
            name: "bookpay_slider_name3",
            content: "bookpay_slider_content3",
            img: {
                src: images[language as Lang].img3,
                alt: "booking",
            },
        }
    ]

    return (
        <section className="bookpay_slider">
            <Sliders
                arr={sliders}
                heading="bookpay_slider_heading"
                title="bookpay_slider_title"
            />
        </section>
    );
};

export default BookpaySlider;
