import React, { FC, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";

import NaviBar from "./components/NaviBar";
import HomePage from "./pages/HomePage";
import BookPayPage from "./pages/BookPayPage";
import InsurancePage from "./pages/InsurancePage";
import ParkingPage from "./pages/ParkingPage";
import Page404 from "./pages/Page404";
import IFramePDF from "./pages/iFramePDF";

import { LangContext } from "./context/lang";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import AutoServicePartnerPage from './pages/AutoServicePartnerPage';

const listDocuments = [
  {
    path: "/terms",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F22092020_BEE%20P!_Terms_ans_Conditions_GENERAL.pdf?alt=media&token=0a4a409e-7f8e-4da3-b532-c06a273406eb",
  },
  {
    // OLD LINK ( new terms-assistance )
    path: "/terms_rsa",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F22092020_%D0%92EEP!_RSA%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D1%8F_%D1%83%D1%81%D0%BB%D1%83%D0%B3_%D0%B0%D1%81%D1%81%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D1%81_UAdocx.pdf?alt=media&token=f79129b1-2f05-406b-b544-1cf171df7d1c",
  },
  {
    path: "/terms-assistance",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F22092020_%D0%92EEP!_RSA%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D0%BD%D0%B8%D1%8F_%D1%83%D1%81%D0%BB%D1%83%D0%B3_%D0%B0%D1%81%D1%81%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D1%81_UAdocx.pdf?alt=media&token=f79129b1-2f05-406b-b544-1cf171df7d1c",
  },
  {
    path: "/terms-partner/en",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F01032021_%D0%92EEP!Partners_Terms%20and%20conditions_EN.pdf?alt=media&token=c82b51c7-825d-45bd-a89a-65d9de2dab80",
  },
  {
    path: "/terms-partner/ru",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F01032021_%D0%92EEP!Partners_%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%B2%D0%B8%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D0%BD%D1%8F_%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%B8_Ukr.pdf?alt=media&token=064504ef-ffb4-4c18-af5c-693a4ef17769",
  },
  {
    path: "/terms-partner/uk",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F01032021_%D0%92EEP!Partners_%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%B2%D0%B8%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D0%BD%D1%8F_%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%B8_Ukr.pdf?alt=media&token=064504ef-ffb4-4c18-af5c-693a4ef17769",
  },
  {
    path: "/agreement-partner",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2F01032021_%D0%92EEP!Partners_%D0%9F%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%BD%D0%B8%D0%B8%CC%86_%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D1%96%D1%80_%D0%B4%D0%BB%D1%8F_%D1%81%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B8%D1%85_%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%96%D0%B2.pdf?alt=media&token=a998cbcb-8fa3-4f2a-9cc0-0e37b4bf1f02",
  },
  {
    path: "/terms-car-auction",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP_CLUB_car_auction.pdf?alt=media&token=60d9cf2c-9a4b-4aff-9370-8384c5883932",
  },
  {
    path: "/terms-loyalty-program",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas.appspot.com/o/APP%2FBeep%2Fterms%2F%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%B8%20%D0%BB%D0%BE%D1%8F%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20Beep%2011072023.pdf?alt=media&token=4cf5408b-7595-4091-a00c-d94197ce24ef",
  },
  // TODO: TR //////
  {
    path: "/terms-car-auction",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP_CLUB_car_auction.pdf?alt=media&token=60d9cf2c-9a4b-4aff-9370-8384c5883932",
  },
  {
    path: "/terms-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP!_General%20T%26C_Turkey_1.pdf?alt=media&token=a6ace565-4d4f-4aab-b514-15fd0c0ab0a6",
  },
  {
    path: "/privacy-policy-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP!_Privacy_Policy_Turkey.pdf?alt=media&token=bd1f3ab3-8265-4494-866b-148f455f9050",
  },
  {
    path: "/service-rules-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP!_Service_Rules_Turkey.docx.pdf?alt=media&token=f04ac94a-b5db-4870-bc6d-88c8be652d5c",
  },
  {
    path: "/terms-assistance-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBeep!%20Assistance%20terms%20TR.pdf?alt=media&token=beb26d0c-c338-41a9-8c8d-0c18d93797f8",
  },
  {
    path: "/terms-loyalty-program-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FReferral%20Programme%20Rules_TR.pdf?alt=media&token=599d2d55-33fe-47ac-93ff-386a8fa39935",
  },
  {
    path: "/terms-car-auction-tr",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP_CLUB_car_auction.pdf?alt=media&token=60d9cf2c-9a4b-4aff-9370-8384c5883932",
  },
  {
    path: "/terms-car-auction",
    url: "https://firebasestorage.googleapis.com/v0/b/bac-main.appspot.com/o/terms%2FBEEP_CLUB_car_auction_TR.pdf?alt=media&token=06f2c0aa-726c-4fa7-994e-8a9f54005815",
  },
  {
    path: "/autoking/tr/customer-consent-form",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAK_Acik_Riza_Metni_TR.pdf?alt=media&token=7d001d43-cc35-4ba9-b9f0-be4fa6420fc0",
  },
  {
    path: "/autoking/tr/loyalty-program-rules",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAK_Referral_Programme_Rules_16032023.pdf?alt=media&token=ded6ba64-97b9-4214-90e0-235229d3879c",
  },

  {
    path: "/autoking/tr/insurance-user-agreement",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/SYSTEM%2Fproviders%2Ftr%2FWAP%2Fkullanici-sozlesmesi.pdf?alt=media&token=010f91e4-26ab-41ef-92d3-6af7accbe072",
  },
  {
    path: "/autoking/tr/insurance-broker",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/SYSTEM%2Fproviders%2Ftr%2FWAP%2Fsigorta-brokeri-nedir.pdf?alt=media&token=f1bf40e9-fde4-4335-9992-9342bd56f537",
  },
  {
    path: "/autoking/tr/protection-of-personal-data",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/SYSTEM%2Fproviders%2Ftr%2FWAP%2Fkisisel-verilerin-korunmasi.pdf?alt=media&token=348f5117-4bb5-4fb8-9806-a6a99687e867",
  },
  {
    path: "/autoking/tr/booking-terms-of-service",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAK_Hizmet_Kosullari_TR.pdf?alt=media&token=8696ce63-df51-4930-b286-33ca282b21c7",
  },
  {
    path: "/autoking/tr/terms-and-conditions",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAK_Mobil_Uygulama_Kullanim_Kosullari_TR.pdf?alt=media&token=fcbc1161-ad49-4ac9-85b8-0ac2a525d166",
  },
  {
    path: "/autoking/tr/privacy-policy",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAK_Gizlilik_Politikasi_TR.pdf?alt=media&token=7878539f-f762-4495-9057-81c44789810a",
  },
  {
    path: "/autoking/tr/auction-terms",
    url: "https://firebasestorage.googleapis.com/v0/b/beep-saas-dev.appspot.com/o/APP%2FAutoKing%2Fterms%2FAUTOKING_Auction_Agreement_Turkey%20(1).pdf?alt=media&token=a7904eba-95e6-4aa7-89b4-0b5b2d017b88",
  },
];
// https://beep.club/autoking/tr/auction-terms
const App: FC = () => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);

  return (
    <>
      <Router>
        <ScrollToTop />
        <div className="loading">
          <Routes>
            <Route index element={<HomePage translate={translate} />} />
            <Route path="/:locale">
              <Route index element={<HomePage translate={translate} />} />
              <Route
                path="bookpay"
                element={<BookPayPage translate={translate} />}
              />
              <Route
                path="insurance"
                element={<InsurancePage translate={translate} />}
              />
              <Route
                path="parking"
                element={<ParkingPage translate={translate} />}
              />
              <Route
                path="service"
                element={<AutoServicePartnerPage translate={translate} />}
              />
              <Route path="home" element={<HomePage translate={translate} />} />
            </Route>

            {listDocuments.map((doc, index) => (
              <Route
                key={`doc-${doc}`}
                path={doc.path}
                element={<IFramePDF link={doc.url} />}
              />
            ))}

            <Route path="*" element={<Page404 translate={translate} />} />
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
