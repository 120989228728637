import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";
import Benefits from "../Benefits";

import "../../scss/_insuranceBenefits.scss";

const InsuranceBenefits: FC = () => {
  const {
    state: { language },
    dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // content for the component Benefits
  return (
    <section className="insurance_benefits">
      <svg
        width="1920"
        height="725"
        viewBox="0 0 1920 725"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="background-line desktop-d"
      >
        <path
          data-v-3612c691=""
          className="path"
          opacity="0.25"
          d="M2.77103e-05 664.248C92.6863 700.101 182.197 747.63 292.154 710.397C587.862 610.268 489.263 454.264 749.794 219.301C1010.32 -15.6634 1335.03 -58.9635 1659.29 79.8252C1738.83 113.867 1828.14 162.691 1920.21 222.064"
          stroke="#404347"
          strokeWidth={2}
          strokeDasharray="6 8"
        />
      </svg>

      <Benefits
        header1={translate("insurance_benefits_subtitle")}
        header2={translate("insurance_benefits_title")}
        content1={translate("insurance_benefits_item1")}
        content2={translate("insurance_benefits_item3")}
        content3={translate("insurance_benefits_item5")}
        content4={translate("insurance_benefits_item2")}
        content5={translate("insurance_benefits_item4")}
        content6={translate("insurance_benefits_item6")}
      />
    </section>
  );
};

export default InsuranceBenefits;
