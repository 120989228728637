import React, { Fragment, FC } from "react";
import ParkingFeedback from "../components/parking/ParkingFeedback";
import ParkingFAQ from "../components/parking/ParkingFAQ";
import ParkingHero from "../components/parking/ParkingHero";
import ParkingJoinNow from "../components/parking/ParkingJoinNow";
import ParkingPartners from "../components/parking/ParkingPartners";
import ParkingServices from "../components/parking/ParkingServices";
import ParkingSlider from "../components/parking/ParkingSlider";
import TemplatePage from "./TemplatePage";

interface ParkingPageProps {
  translate: (key: string) => string;
}

const ParkingPage: FC<ParkingPageProps> = ({ translate }) => {
  return (
    <TemplatePage>
      <div className="parking_vector1">
        <svg
          width="1543"
          height="2458"
          viewBox="0 0 1543 2458"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-v"
        >
          <path
            data-v-3612c691=""
            className="path"
            opacity="0.25"
            d="M0.00012207 33.3376C0.00012207 33.3376 587.584 -94.5542 719 158.337C789.108 293.251 662.556 413.664 735 547.337C846.555 753.178 1095.45 578.811 1302.19 741.53C1508.94 904.249 1582.17 1138.74 1520.77 1396.02C1461.6 1643.93 1486.5 1870 1030.5 2213.84C574.501 2557.68 0.00012207 2436 0.00012207 2436"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <ParkingHero />
        <div className="parking_background_service-first">
          <div className="parking_background_service-second">
            <ParkingServices />
            <ParkingPartners />
          </div>
        </div>
        <div className="parking_back_slider-join">
          <ParkingSlider />
          <div className="parking_back_join-faq">
            <ParkingJoinNow />
            <ParkingFAQ />
          </div>
        </div>
      </div>
      <ParkingFeedback />
    </TemplatePage>
  );
};

export default ParkingPage;
