import React, { Fragment, FC } from "react";
import InsuranceBenefits from "../components/insurance/InsuranceBenefits";
import FAQ from "../components/insurance/FAQ";

import Hero from "../components/insurance/Hero";
import InsuranceSlider from "../components/insurance/InsuranceSlider";
import InsuranceJoinNow from "../components/insurance/InsuranceJoinNow";
import Protection from "../components/insurance/Protection";
import InsuranceReviews from "../components/insurance/InsuranceReviews";
import TemplatePage from "./TemplatePage";

interface InsurancePageProps {
  translate: (key: string) => string;
}

const InsurancePage: FC<InsurancePageProps> = ({ translate }) => {
  return (
    <TemplatePage>
      <Hero />
      <Protection />
      <InsuranceSlider />
      <div className="container-vector">
        <svg
          width="1023"
          height="703"
          viewBox="0 0 1023 703"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-t"
        >
          <path
            data-v-3612c691=""
            className="path_insurance"
            opacity="0.25"
            d="M-359 127.027C-313.308 100.407 -269.351 77.9171 -228.785 60.5545C14.3837 -43.5259 257.89 -11.0541 453.267 165.15C648.644 341.354 518.94 616.181 740.697 691.27C868.833 734.658 959.945 624.556 1080.5 632.335"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <svg
          width="767"
          height="703"
          viewBox="0 0 767 703"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="background-line desktop-m"
        >
          <path
            data-v-3612c691=""
            className="path_insurance"
            opacity="0.25"
            d="M-645 127.027C-599.308 100.407 -555.35 77.9168 -514.785 60.5542C-271.616 -43.5261 -28.1099 -11.0544 167.267 165.15C362.644 341.354 232.94 616.181 454.697 691.27C582.833 734.658 673.945 624.556 794.5 632.335"
            stroke="#404347"
            strokeWidth={2}
            strokeDasharray="6 8"
          />
        </svg>

        <InsuranceBenefits />
        <InsuranceReviews />
        <div className="container-baground">
          <InsuranceJoinNow />
          <FAQ />
        </div>
      </div>
    </TemplatePage>
  );
};

export default InsurancePage;
