import React from 'react';
import { Box, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface IImage {
    id: number;
    src: string;
    file: File;
}

interface IPayload {
    selectedFiles: IImage[];
    setSelectedFiles: (func: (images: IImage[]) => IImage[]) => void;
    formSubmitted: boolean;
    error: boolean;
    translate: (key: string) => string
}

const ImageUploadButton = (payload: IPayload) => {
    const { selectedFiles, setSelectedFiles, formSubmitted, error, translate } = payload

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const src = URL.createObjectURL(file);
                const image: IImage = {
                    id: Date.now() + i, src, file
                };

                setSelectedFiles((prevFiles: IImage[]) => [...prevFiles, image]);
            }
        }
    };

    const removeImage = (id: number) => {
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };
    let className = "image-plus";

    if (formSubmitted && error) {
        className += " error";
    }
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    sx={{
                        backgroundColor: '#ffffff26',
                        padding: '20px',
                        borderRadius: '20px',
                        display: 'flex',
                        '&:hover': {
                            borderColor: '#f7b500 !important',
                            borderWidth: '2px !important',
                        },
                        '&:focus': {
                            borderColor: '#ffffff26 !important',
                        },
                    }}
                    onClick={() => {
                        const inputFileElement = document.getElementById(`upload-photo`)
                        if (inputFileElement) {
                            inputFileElement.click()
                        }
                    }}
                >
                    <svg width="37" height="33" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.2787 9.3175L34.1787 24.3325C33.7237 27.4825 31.9912 29.145 29.1387 29.145C28.9112 29.145 28.6837 29.1275 28.4387 29.11C28.4893 28.7682 28.5127 28.423 28.5087 28.0775V12.905C28.5974 12.2714 28.5376 11.6259 28.3339 11.0194C28.1302 10.4129 27.7882 9.86211 27.335 9.41057C26.8817 8.95903 26.3297 8.61911 25.7224 8.41769C25.1152 8.21626 24.4694 8.15885 23.8362 8.25H6.38867C6.38867 8.215 6.40617 8.1625 6.40617 8.1275L6.82617 5.1875C6.8811 4.43851 7.08418 3.70788 7.42364 3.03798C7.76309 2.36807 8.23217 1.77223 8.80367 1.285C9.43047 0.872012 10.1326 0.586772 10.8698 0.445632C11.6071 0.304491 12.3649 0.310225 13.0999 0.462503L31.5799 3.0525C32.3271 3.11237 33.055 3.31946 33.7217 3.66186C34.3885 4.00425 34.9809 4.47517 35.4649 5.0475C35.8831 5.66684 36.1709 6.36478 36.3108 7.09885C36.4506 7.83292 36.4397 8.58778 36.2787 9.3175Z" fill="#F7B500" />
                        <g opacity="0.4">
                            <path d="M0.500057 28.0827V12.9137C0.409971 12.28 0.468623 11.634 0.671373 11.0268C0.874123 10.4196 1.21541 9.86798 1.66821 9.41551C2.12102 8.96305 2.67292 8.62218 3.28024 8.41988C3.88755 8.21759 4.53362 8.15942 5.16731 8.24998H23.8363C24.4697 8.1604 25.1152 8.21928 25.7218 8.42198C26.3285 8.62467 26.8798 8.96564 27.3321 9.41794C27.7844 9.87024 28.1254 10.4215 28.3281 11.0282C28.5308 11.6349 28.5896 12.2804 28.5001 12.9137V28.0827C28.5902 28.7164 28.5318 29.3623 28.3294 29.9694C28.127 30.5765 27.7861 31.1283 27.3337 31.581C26.8813 32.0337 26.3298 32.375 25.7228 32.5779C25.1159 32.7808 24.47 32.8397 23.8363 32.75H5.16731C4.53329 32.8407 3.88686 32.7825 3.27924 32.58C2.67161 32.3775 2.11949 32.0363 1.66661 31.5834C1.21373 31.1306 0.872529 30.5784 0.670048 29.9708C0.467568 29.3632 0.409367 28.7168 0.500057 28.0827Z" fill="#F7B500" />
                        </g>
                        <path d="M18.875 19.1875H15.8125V16.125C15.8125 15.7769 15.6742 15.4431 15.4281 15.1969C15.1819 14.9508 14.8481 14.8125 14.5 14.8125C14.1519 14.8125 13.8181 14.9508 13.5719 15.1969C13.3258 15.4431 13.1875 15.7769 13.1875 16.125V19.1875H10.125C9.7769 19.1875 9.44306 19.3258 9.19692 19.5719C8.95078 19.8181 8.8125 20.1519 8.8125 20.5C8.8125 20.8481 8.95078 21.1819 9.19692 21.4281C9.44306 21.6742 9.7769 21.8125 10.125 21.8125H13.1875V24.875C13.1875 25.2231 13.3258 25.5569 13.5719 25.8031C13.8181 26.0492 14.1519 26.1875 14.5 26.1875C14.8481 26.1875 15.1819 26.0492 15.4281 25.8031C15.6742 25.5569 15.8125 25.2231 15.8125 24.875V21.8125H18.875C19.2231 21.8125 19.5569 21.6742 19.8031 21.4281C20.0492 21.1819 20.1875 20.8481 20.1875 20.5C20.1875 20.1519 20.0492 19.8181 19.8031 19.5719C19.5569 19.3258 19.2231 19.1875 18.875 19.1875Z" fill="#F7B500" />
                    </svg>
                    <input
                        id="upload-photo"
                        type="file"
                        multiple
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={handleFileChange}
                    />
                </IconButton>
                <label className={className} htmlFor="upload-photo">
                    {translate('сar_srvc_partner_photo_btn')}
                </label>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: '1em',
                }}
            >
                {selectedFiles.map((file) => (
                    <Box key={file.id} sx={{ position: 'relative', margin: '0.5em', }}>
                        <Avatar src={file.src} alt="" variant="rounded" sx={{ width: 64, height: 64, borderRadius: 4 }} />
                        <IconButton
                            size="small"
                            sx={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                backgroundColor: '#131518',
                                color: 'white',
                            }}
                            onClick={() => removeImage(file.id)}
                        >
                            <CloseIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                }}
                            />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ImageUploadButton;
