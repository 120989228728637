import React, { FC, useContext } from "react";
import LangState, { LangContext } from "../../context/lang";
import Sliders from "../Sliders";

import Img1En from "../../img/en/parking/slider/sl_1_en.png";
import Img2En from "../../img/en/parking/slider/sl_2_en.png";
import Img3En from "../../img/en/parking/slider/sl_3_en.png";
import Img4En from "../../img/en/parking/slider/sl_4_en.png";
import Img1Uk from "../../img/uk/parking/slider/sl_1_uk.png";
import Img2Uk from "../../img/uk/parking/slider/sl_2_uk.png";
import Img3Uk from "../../img/uk/parking/slider/sl_3_uk.png";
import Img4Uk from "../../img/uk/parking/slider/sl_4_uk.png";
import Img1Tr from "../../img/tr/parking/slider/sl_1_tr.png";
import Img2Tr from "../../img/tr/parking/slider/sl_2_tr.png";
import Img3Tr from "../../img/tr/parking/slider/sl_3_tr.png";
import Img4Tr from "../../img/tr/parking/slider/sl_4_tr.png";

import "../../scss/_parkingSlider.scss";
import { ISlidersProps } from "../interfaces/ISlidersProps";

// Images for Grid. src
const images = {
  EN: {
    img1: Img1En,
    img2: Img2En,
    img3: Img3En,
    img4: Img4En,
  },
  UK: {
    img1: Img1Uk,
    img2: Img2Uk,
    img3: Img3Uk,
    img4: Img4Uk,
  },
  TR: {
    img1: Img1Tr,
    img2: Img2Tr,
    img3: Img3Tr,
    img4: Img4Tr,
  },
};

type Lang = "EN" | "UK" | "TR";

const ParkingSlider: FC = () => {
  const {
    state: { language },
    // dispatch: { setLanguage, translate },
  } = useContext(LangContext);

  // Array to fill with slider content
  const sliders: ISlidersProps[] = [
    {
      name: "parking_slider_name1",
      content: "parking_slider_content1",
      img: {
        src: images[language as Lang].img1,
        alt: "add parking place",
      },
    },
    {
      name: "parking_slider_name2",
      content: "parking_slider_content2",
      img: {
        src: images[language as Lang].img2,
        alt: "publish parking place",
      },
    },
    {
      name: "parking_slider_name3",
      content: "parking_slider_content3",
      img: {
        src: images[language as Lang].img3,
        alt: "Accept requests",
      },
    },
    {
      name: "parking_slider_name4",
      content: "parking_slider_content4",
      img: {
        src: images[language as Lang].img4,
        alt: "Get paid",
      },
    },
  ];

  return (
    <section className="parking_slider">
      {/* <div className="slider-section_header">
        <h6>{translate("parking_slider_heading")}</h6>
        <h2 className="item-header">{translate("parking_slider_title")}</h2>
      </div> */}
      <Sliders
        arr={sliders}
        heading="parking_slider_heading"
        title="parking_slider_title"
      />
    </section>
  );
};

export default ParkingSlider;
